import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import addTotem from "../../services/collection/totem/addTotem";
import deleteTotem from "../../services/collection/totem/deleteTotem";
import editTotem from "../../services/collection/totem/editTotem";
import ConfirmButton from "../buttons/ConfirmButton";
import CloseModal from "./CloseModal";

const TotemModal = ({ surveys, show, data = {} }) => {
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);
  const [totem, setTotem] = useState({ ...data });
  
  useEffect(() => {
    setTotem({ ...data });
  }, [data]);

  const handleConfirmClose = () => {
    setTotem({});
    show.setter(false);
    setShowCloseConfirmation(false);
  };

  const handleDelete = async (totem) => {
    await deleteTotem(totem);
    show?.setter(false);
  };

  const handleSubmit = async () => {
    if (totem?._id) {
      await editTotem({ ...totem });
      setTotem({});
      show?.setter(false);
    } else {
      await addTotem({ ...totem, password: "Password_1" });
      setTotem({});
      show?.setter(false);
    }
  };

  return (
    <>
      <Modal
        show={show?.getter}
        backdrop="static"
        keyboard={false}
        className="modal-add"
      >
        <Modal.Header >
          <Modal.Title>
            <h4 className="m-0">
              {(data?._id && "Modifica il totem") || "Aggiungi un nuovo Totem"}
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <label htmlFor="totem-name">Nome del Totem * :</label>
            <br />
            <input
              type="text"
              id="totem-name"
              name="totem-name"
              value={totem?.email || ""}
              onChange={(e) => setTotem({ ...totem, email: e.target.value })}
              className="input mb-2"
              autoComplete="off"
            />
            <br />

            <label htmlFor="totem-desc">Descrizione :</label>
            <br />
            <textarea
              id="totem-desc"
              name="totem-desc"
              value={totem?.description || ""}
              onChange={(e) =>
                setTotem(() => {
                  return { ...totem, description: e.target.value };
                })
              }
              rows="4"
              cols="50"
              className="textarea"
            ></textarea>

            <div className="row w-100">
              <div className="col-6">
              <div className="select-option ">
              <label htmlFor="totem-desc">Survey in Italiano:</label>
              <select
                value={totem?.surveyIT || ''}
                onChange={(e) => {
                  const selectedSurvey = e.target.value; 
                  const updatedTotem = {
                    ...totem,
                    surveyIT: selectedSurvey || '0'
                  };
                  setTotem(updatedTotem);
                }}
                className={"input"}
              >
                <option key="" value="">
                 Nessun survey selezionato
                </option>

                {surveys?.filter(survey=>survey?.language=='it')?.map((survey, index) => (
                  <option key={index} value={survey?._id}>
                    {survey?.language?.toUpperCase()} - {survey?.name}
                  </option>
                ))}
              </select>
            </div>
              </div>
              <div className="col-6">
              <div className="select-option ">
              <label htmlFor="totem-desc">Survey in inglese:</label>
              <select
                value={totem?.surveyEN || ''}
                onChange={(e) => {
                  const selectedSurvey = e.target.value; 
                  const updatedTotem = {
                    ...totem,
                    surveyEN: selectedSurvey || '0'
                  };
                  setTotem(updatedTotem);
                }}
                className={"input"}
              >
                <option key="" value="">
                 Nessun survey selezionato
                </option>

                {surveys?.filter(survey=>survey?.language=='en')?.map((survey, index) => (
                  <option key={index} value={survey?._id}>
                    {survey?.language?.toUpperCase()} - {survey?.name}
                  </option>
                ))}
              </select>
            </div>
              </div>
            </div>

           

            <div className="row w-100">

              <div className="col-4">
                <label className="" htmlFor="totem-desc">
                  Data scadenza contratto
                </label>
                <br />

                <input
                  type="date"
                  name="totem-dataScadenza"
                  value={totem?.contractExpirationDate?.split('T')[0] || ''}
                  onChange={(e) => setTotem({ ...totem, contractExpirationDate: e.target.value })}
                  className="input mb-2"
                  autoComplete="off"
                />

              </div>

              {(data?._id && (

                <div className="col-8">
                  <label className="" htmlFor="totem-desc">
                    Nuova Password :
                  </label>
                  <br />
                  <input
                    type="text"
                    id="totem-password"
                    name="totem-password"
                    value={totem?.password}
                    onChange={(e) =>
                      setTotem({ ...totem, password: e.target.value })
                    }
                    className="input mb-2"
                    autoComplete="off"
                  />

                  <h6 className="text-muted small mt-2 ps-2">
                    Inserire la password solo se si vuole modificarla
                  </h6>
                </div>

              )) || (
                  <div className="col-8">
                    <h6 className="text-muted small text-end pt-5 mt-2">
                      La password di default sarà: <i>Password_1</i>
                    </h6>
                  </div>
                )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className={`${data?._id ? 'd-flex justify-content-between' : ''}`}>
          {(data?._id && (
            <div className="left-btns">
              <ConfirmButton
                className="me-5"
                label="Ellimina Totem"
                danger={true}
                action={handleDelete}
                data={data}
              />
            </div>
          )) || <></>}
          <div className="right-btns">
            <button
              className="cancel-btn  mx-3"
              onClick={() => setShowCloseConfirmation(true)}
            >
              Annulla
            </button>
            <button type="button" className="main-btn" onClick={handleSubmit} disabled={!totem?.email}>
              {(data?._id && "Aggiorna") || "Aggiungi"}
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Alert di conferma chiusura modale senza salvare possibili modifiche */}
      {showCloseConfirmation && (
        <CloseModal show={showCloseConfirmation}
          onConfirmClose={handleConfirmClose}
          onDontClose={() => setShowCloseConfirmation(false)}
        />
      )}
    </>
  );
};

export default TotemModal;
