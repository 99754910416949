 const downloadCSV = async (options) => {
  try {
    const baseUrl = process.env.REACT_APP_API_URL;
    const tokenName = process.env.REACT_APP_TOKEN;

    const token = localStorage.getItem(tokenName) || null;
    
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    let fetchOptions = {
      method: 'GET', 
      headers,
    };

    const response = await fetch(`${baseUrl}/csv`, fetchOptions);

    if (!response.ok) {
      throw new Error('Errore durante il download del file CSV');
    }

    const contentDisposition = response.headers.get('Content-Disposition');
    let filename = 'download.csv'; // Nome di default per il file
    if (contentDisposition) {
      const matches = /filename="([^"]+)"/.exec(contentDisposition);
      if (matches.length === 2) {
        filename = matches[1];
      }
    }

    const blob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(downloadUrl);
    a.remove();
  } catch (error) {
    console.error('Errore nel download del CSV:', error);
  }
};

export default downloadCSV;
