import request from '../../request';

const retrieveTotems = async () => {

    const options = {
        url: `/users`,
        method: 'GET',
    }

    return await request({...options});

};

export default retrieveTotems;
