import request from "../../request";

const editProduct = async (product) => {
  const options = {
    url: `/products/${product?._id}`,
    method: "PUT",
    payload: product,
  };

  return await request({ ...options });
};

export default editProduct;
