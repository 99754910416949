import request from "../../request";

const deleteSurvey = async (survey) => {
  const options = {
    url: `/surveys/${survey?._id}`,
    method: "DELETE",
  };

  return await request({ ...options });
};

export default deleteSurvey;
