import request from "../../request";

const addProduct = async (product) => {
  const options = {
    url: `/products`,
    method: "POST",
    payload: product,
  };

  return await request({ ...options });
};

export default addProduct;
