import { Card, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Bounce, Fade, Flip } from "react-awesome-reveal";
import { useLanguage } from "../services/functions/LanguageProvider";
import { Link } from "react-router-dom";
import jsPDF from "jspdf";


const Recommendations = () => {
  const { language, translations } = useLanguage();

  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [productsRecommended, setProductsRecommended] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedCurrent = localStorage.getItem("TotemVitaMatchCURRENT") || null;
    const currentResult = storedCurrent ? JSON.parse(storedCurrent) : {};

    const storedProducts =
      localStorage.getItem("TotemVitaMatchPRODUCTS") || null;
    const products = storedProducts ? JSON.parse(storedProducts) : {};

    const currentProducts = products[language] || [];

    const suggestedProducts = currentProducts
      ?.filter((item) => {
        return item?.recommendedFor?.some((recommendedItem) =>
          currentResult?.suggestedProducts?.includes(recommendedItem)
        );
      })
      ?.map((item) => {
        return {
          imgSrc: item?.image?.imageUrl || "/assets/images/product-default.png",
          title: item?.name,
          description: item?.description,
          button: translations[language]?.common?.details,
        };
      });

    setProductsRecommended(() => suggestedProducts);

    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, [language, translations]);

  const handleClose = () => setShowModal(false);
  const handleShow = (product) => {
    setSelectedProduct(product);
    setShowModal(true);
  };

  //DOWNLOAD DEI PROD.RACCOMAND. AL CLICK SU "PARLANE COL TO FARMACISTA"
  const downloadPDF = () => {
    const doc = new jsPDF();
    const pageHeight = doc.internal.pageSize.height;
    const pageWidth = doc.internal.pageSize.width;
    const margin = 10; // Margine ridotto
    const headerHeight = 30;
    const footerHeight = 15;
    const lineSpacing = 4; // Distanza tra descrizione e linea di separazione
    const productSpacing = 4; // Distanza tra prodotti
    const imageWidth = 18;
    let yPosition = headerHeight + 10;
    let pageNumber = 1;

    // Funzione per calcolare l'altezza mantenendo le proporzioni
    const getAutoHeight = (imgSrc, width) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = imgSrc;
        img.onload = () => {
          const ratio = img.height / img.width;
          const height = width * ratio;
          resolve(height);
        };
        img.onerror = reject;
      });
    };

    // Funzione per aggiungere il footer
    const addFooter = (pageNum) => {
      doc.setFontSize(10);
      doc.setFont("helvetica", "italic");
      doc.text(`${translations[language]?.common?.generatedBy} TotemVitaMatch`, margin, pageHeight - footerHeight);
      doc.text(`Page ${pageNum}`, pageWidth - margin, pageHeight - footerHeight, { align: "right" });
    };

    // Header
    doc.addImage('/assets/images/logo/vitamatch.png', 'PNG', pageWidth - margin - 60, margin, 60, 20); // Logo al lato destro
    yPosition += 15; // Spazio sotto il logo
    doc.setFontSize(16);
    doc.setFont("helvetica", "bold");
    doc.text(translations[language]?.common?.productsRecommended, margin, yPosition);
    yPosition += 15; // Spazio sotto il titolo principale

    // Corpo del PDF
    const addProduct = async (product) => {
      const imageHeight = await getAutoHeight(product.imgSrc, imageWidth); // Calcolo dell'altezza auto dell'img product

      if (yPosition + imageHeight + lineSpacing * 3 > pageHeight - footerHeight) {
        addFooter(pageNumber);
        doc.addPage();
        yPosition = headerHeight + 10;
        pageNumber += 1;
      }

      // Immagine del prodotto
      if (product.imgSrc) {
        doc.addImage(product.imgSrc, 'PNG', margin, yPosition, imageWidth, imageHeight);
      }

      // Titolo del prodotto
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      const titleYPos = product.imgSrc ? yPosition + 5 : yPosition;
      doc.text(product.title, margin + imageWidth + 8, titleYPos);

      // Descrizione del prodotto
      doc.setFontSize(10);
      doc.setFont("helvetica", "normal");
      const descriptionWidth = pageWidth - margin * 2 - imageWidth - 10;
      const descriptionLines = doc.splitTextToSize(product.description, descriptionWidth);
      doc.text(descriptionLines, margin + imageWidth + 8, titleYPos + lineSpacing);

      // Linea di separazione
      yPosition += imageHeight;
      yPosition += lineSpacing * 2;
      doc.setDrawColor(0, 0, 0);
      doc.line(margin, yPosition, pageWidth - margin, yPosition);

      yPosition += productSpacing;
    };

    // Aggiungi ogni prodotto
    productsRecommended.reduce((promise, product) => {
      return promise.then(() => addProduct(product));
    }, Promise.resolve()).then(() => {
      // Richiamo il footer
      addFooter(pageNumber);
      // Salva il PDF
      doc.save(`${translations[language]?.common?.productsRecommended}.pdf`);
    });
  };


  return (
    <>
      {loading ? (
        <div className="loading-container d-flex justify-content-center">
          <div className="spinner-border color-spinner " role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <div className="text">
            <p id="loading-text">
              {translations[language]?.common?.loadingPage}
              <span id="dots"></span>
            </p>
          </div>
        </div>
      ) : productsRecommended?.length === 0 ? (
        <Fade>
          <div className="recommendations no-products-container">
            <div className="leftside">
              <div className="logo text-center">
                <img src="/assets/images/logo/vitamatch.png" alt="" />
              </div>

              <div className="welcome-text text-center ">
                <h5 className="title">
                  {translations[language]?.common?.noProductsAvailable}
                </h5>

                <div className="btn-group">
                  <Link to="/">
                    <button className="main-btn">
                      {translations[language]?.common?.backToHome}
                    </button>
                  </Link>
                </div>
                {/* <p className="subtitle">
                {translations[language]?.common?.greetingSubtitle}
              </p> */}
              </div>
            </div>
          </div>
        </Fade>
      ) : (
        <Fade>
          <div className="recommendations ">

            <div className="leftside">
              <div className="nav">
                <button type="button" className="back-home main-btn">
                  <a href="/">{translations[language]?.common?.backToHome}</a>
                </button>
                <div className="logo text-center">
                  <img src="/assets/images/logo/vitamatch.png" alt="Logo" />
                </div>
                <button type="button" className="main-btn right-btn" onClick={downloadPDF}>
                  {translations[language]?.common?.askToPharmacist}
                </button>
                <button
                  type="button"
                  className="mobile-btn back-home main-btn"
                > <a href="/">
                    <img src="/assets/svg/home.svg" alt="Back Home" />
                  </a>
                </button>
                <button
                  type="button"
                  className="mobile-btn print main-btn"
                  onClick={downloadPDF}
                >
                  <img src="/assets/svg/print.svg" alt="Print" />
                </button>
              </div>

              

              <div className="welcome-text text-center ">
                <h5 className="title">
                  {translations[language]?.common?.greetingTitle}
                </h5>
                <p className="subtitle">
                  {translations[language]?.common?.greetingSubtitle}
                </p>
              </div>
            </div>

            <div className="rightside">
              <div className="products-wrap">
                <div className="row products-container">
                  {productsRecommended?.map((product, index) => (
                    <Card key={index}>
                      <Card.Img
                        variant="top"
                        className="product-img"
                        src={product?.imgSrc}
                      />
                      <Card.Body>
                        <div className="title">
                          <Card.Title>{product?.title}</Card.Title>
                          <Card.Text>
                            {product?.description?.length > 40
                              ? `${product?.description?.substring(0, 40)}...`
                              : product?.description}
                          </Card.Text>
                        </div>

                        <button
                          variant="primary"
                          className="main-btn product-btn"
                          onClick={() => handleShow(product)}
                        >
                          {product?.button}
                        </button>
                      </Card.Body>
                    </Card>
                  ))}
                </div>
                <div className="warning">
                  <OverlayTrigger
                    placement="left"
                    overlay={
                      <Tooltip id="tooltip" className="custom-tooltip ">
                        {translations[language]?.common?.warningTooltip}
                      </Tooltip>
                    }
                  >
                    <img src="./assets/svg/warning.svg" alt="" />
                  </OverlayTrigger>
                </div>
              </div>
            </div>

            <Modal
              show={showModal}
              keyboard={false}
              className="recommendation-modal"
            >
              {selectedProduct && (
                <>
                  {/* <Modal.Header closeButton>
                    <Modal.Title>{selectedProduct?.title}</Modal.Title>
                  </Modal.Header> */}
                  <Modal.Body>
                    <div className="modal-product-img col-5">
                      <img src={selectedProduct?.imgSrc} alt="" />
                    </div>

                    <div className="detail col-7">
                      <div className="upper-side">
                        <div className="title mb-4">
                          <h4 className="mb-0">{selectedProduct?.title}</h4>
                          <button onClick={handleClose}>
                            <img
                              src="/assets/svg/close.svg"
                              style={{ width: "30px", height: "30px" }}
                              alt="Close"
                            />
                          </button>
                        </div>
                        <hr />
                        <div className="description">
                          <p>{selectedProduct?.description}</p>
                        </div>
                      </div>

                      {/* <div className="recommendation-modal-buttons">
                        <button type="button" className="main-btn right-btn">
                          {translations[language]?.common?.askToPharmacist}
                        </button>
                        <button type="button" className="main-btn">
                          <img src="/assets/svg/add-cart.svg" alt="" />
                        </button>
                      </div> */}
                    </div>
                  </Modal.Body>
                  {/* <Modal.Footer>
                    <button type="button" className="main-btn" onClick={handleClose}>
                      Capito
                    </button>
                  </Modal.Footer> */}
                </>
              )}
            </Modal>
          </div>
        </Fade>
      )}
    </>
  );
};

export default Recommendations;
