import React from 'react'
import { Modal } from 'react-bootstrap'

const CloseModal = ({ show, onDontClose, onConfirmClose }) => {
    return (
        <div className="confirmation-alert">
            <Modal
                show={show}
                onHide={onDontClose}
                backdrop="static"
                keyboard={false}
                className="alert-modal"
            >
                <Modal.Body>
                    <p className="m-0">
                        Eventuali modifiche <b>non</b> verrano salvate. <br /> Sei sicur*
                        di voler chiudere?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="main-btn" onClick={onConfirmClose}>
                        Sì
                    </button>
                    <button className="cancel-btn px-5" onClick={onDontClose}>
                        No
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default CloseModal