import { useState, useEffect } from "react";
import { Fade } from "react-awesome-reveal";
import retrieveProducts from "../services/collection/products/retrieveProducts";
import SearchBar from "./SearchBar";
import ProductModal from "./modals/ProductModal";
import { getImageByResolution } from "../services/cloudinary";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ImagePreviewModal from "./modals/ImagePreviewModal";

const ProductsTable = () => {
  const [showModal, setShowModal] = useState(false);
  const [products, setProducts] = useState([]);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [filteredResults, setFilteredResults] = useState([]);
  const [search, setSearch] = useState("");
  const [showImagePreview, setShowImagePreview] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [imagePreviewTitle, setImagePreviewTitle] = useState("");

  const handleProducts = async () => {
    const response = await retrieveProducts();
    const sortedProducts = response?.data?.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    setProducts(() => response?.data);
    setFilteredResults(() => sortedProducts);
  };

  const handleEditProduct = (product) => {
    setCurrentProduct(() => product);
    setShowModal(() => true);
  };

  const handleImagePreview = (product) => {
    if (product?.image?.imageUrl) {
      setImagePreviewUrl(product.image.imageUrl);
      setImagePreviewTitle(product.name);
      setShowImagePreview(true);
    } else {
      setShowImagePreview(false);
    }
  };

  useEffect(() => {
    const filteredItems = products?.filter(
      (product) =>
        product?.name?.toLowerCase()?.includes(search?.toLowerCase()) ||
        product?.description?.toLowerCase()?.includes(search?.toLowerCase())
    );
    const sortedFilteredItems = filteredItems?.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    setFilteredResults(sortedFilteredItems);
  }, [search]);

  useEffect(() => {
    handleProducts();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    handleProducts();
  }, [showModal]);

  return (
    <>
      <div className="products-list container bg-color">
        <Fade>
          <div className="row">
            <div className="table-header py-3">
              <h3 className="list-title">Lista Prodotti</h3>
            </div>
            <div className="table-navbar col-12">
              <div className="right-section col-12">
                <div className="search-add">
                  <SearchBar searchGetter={search} searchSetter={setSearch} />
                </div>
                {!search && (
                  <div className="container-add">
                    <button
                      type="button"
                      className="main-btn add-icon"
                      onClick={() => handleEditProduct(true)}
                    >
                      <img src="/assets/svg/add-circle.svg" alt="" />
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className="table-container">
              {(filteredResults?.length > 0 && (
                <table className="table table-responsive table-borderless">
                  <thead>
                    <tr>
                      <th colSpan={1}>#</th>
                      <th colSpan={2}>Immagine</th>
                      <th colSpan={3}>Nome</th>
                      <th colSpan={4} className="description-field">
                        Descrizione
                      </th>
                      <th colSpan={2}>Azione</th>
                    </tr>
                  </thead>

                  <tbody>
                    {filteredResults?.map((product, index) => (
                      <tr key={product?._id}>
                        <td colSpan={1}>{index + 1}</td>
                        <td
                          colSpan={2}
                          onClick={() => handleImagePreview(product)}
                        >
                          {(product?.image?.imageUrl && (
                            <img
                              src={getImageByResolution(
                                product?.image?.imageUrl,
                                30
                              )}
                              alt="..."
                              className="table-image"
                            ></img>
                          )) || <></>}
                        </td>
                        <td colSpan={3}>
                          {(product?.name?.length > 30 &&
                            `${product?.name?.slice(0, 30)}...`) ||
                            product?.name}
                        </td>
                        <td colSpan={4}>
                          {(product?.description?.length > 50 &&
                            `${product?.description?.slice(0, 50)}...`) ||
                            product?.description}
                        </td>
                        <td
                          colSpan={2}
                          onClick={() => handleEditProduct(product)}
                        >
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="tooltip-edit">Modifica</Tooltip>
                            }
                          >
                            <img
                              className="setting"
                              src="/assets/svg/edit.svg"
                              alt=""
                              style={{ width: "20px", height: "20px" }}
                            />
                          </OverlayTrigger>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )) || (
                <div className="no-results">
                  <h5 className="text-center mt-5 text-muted">
                    Nessun dato trovato
                  </h5>
                </div>
              )}
            </div>
          </div>
        </Fade>
      </div>

      <ProductModal
        show={{ getter: showModal, setter: setShowModal }}
        data={currentProduct}
      />

      <ImagePreviewModal
        show={showImagePreview}
        onHide={() => setShowImagePreview(false)}
        imageUrl={imagePreviewUrl}
        title={imagePreviewTitle}
      />
    </>
  );
};

export default ProductsTable;
