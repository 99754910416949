import request from "../../request";

const downloadSurveyJSON = async (survey) => {
  const options = {
    url: `/surveys/${survey?._id}/download`,
    method: "GET",
    payload: null,
  };

  return await request({ ...options });
};

export default downloadSurveyJSON;
