import { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap"
import addSurvey from "../../services/collection/surveys/addSurvey";
import deleteSurvey from "../../services/collection/surveys/deleteSurvey";
import editSurvey from "../../services/collection/surveys/editSurvey";

import ConfirmButton from "../buttons/ConfirmButton";
import CloseModal from "./CloseModal";

const SurveyModal = ({ show, data = {} }) => {

    const [survey, setSurvey] = useState({ ...data });
    const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);

    const standardQuestions = [
        {
            questionType: "a",
            questionText: "Scegli la tua età",
            possibleAnswers: [
                {
                    answerType: "a",
                    answerText: "<20",
                    recommendedProducts: [],
                    notRecommendedProducts: []
                },
                {
                    answerType: "b",
                    answerText: "21-32",
                    recommendedProducts: [],
                    notRecommendedProducts: []
                },
                {
                    answerType: "c",
                    answerText: "33-44",
                    recommendedProducts: [],
                    notRecommendedProducts: []
                },
                {
                    answerType: "d",
                    answerText: "45-60",
                    recommendedProducts: [],
                    notRecommendedProducts: []
                },
                {
                    answerType: "e",
                    answerText: "60+",
                    recommendedProducts: [],
                    notRecommendedProducts: []
                },
            ],
        },
        {
            questionType: "b",
            questionText: "Sei...",
            possibleAnswers: [
                {
                    answerType: "a",
                    answerText: "Uomo",
                    recommendedProducts: [],
                    notRecommendedProducts: []
                },
                {
                    answerType: "b",
                    answerText: "Donna",
                    recommendedProducts: [],
                    notRecommendedProducts: []
                },
                {
                    answerType: "c",
                    answerText: "Preferisco non specificare",
                    recommendedProducts: [],
                    notRecommendedProducts: []
                },
            ],
        },
    ];

    useEffect(() => {
        setSurvey({ ...data });
    }, [data]);

    const handleConfirmClose = () => {
        show.setter(false);
        setSurvey({});

        setShowCloseConfirmation(false);
    };

    const handleClose = () => {
        setSurvey({});
        show?.setter(false);
    };


    const handleSubmit = async () => {
        if (survey?._id) {
            await editSurvey({ ...survey });
            setSurvey({});
            show?.setter(false);
        } else {
            //AGGINGI DOMANDE STANDARD
            await addSurvey({
                ...survey,
                questions: [...standardQuestions],
            });
            setSurvey({});
            show?.setter(false);
        }
    };

    const handleDelete = async (survey) => {
        await deleteSurvey(survey);
        show?.setter(false);
    };

    return (
        <>
            <Modal
                show={show?.getter}
                onHide={() => handleClose()}
                backdrop="static"
                keyboard={false}
                className="modal-add"
            >
                <Modal.Header >
                    <Modal.Title>
                        <h4 className="m-0">
                            {data?._id ? (<>Modifica : <i>{survey?.name}</i></>) : ("Aggiungi un nuovo Survey")}
                        </h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <label htmlFor="survey-name">Nome del Survey :</label>
                        <br />
                        <input
                            type="text"
                            id="survey-name"
                            name="survey-name"
                            value={survey?.name || ""}
                            onChange={(e) => setSurvey({ ...survey, name: e.target.value })}
                            className="input mb-2"
                            autoComplete="off"
                        />
                        <label htmlFor="survey-select">Lingua:</label>
                        <br />
                        <Form.Select
                            aria-label="Seleziona una lingua"
                            className="input language"
                            value={survey?.language || ""}
                            
                            onChange={(e) => setSurvey({ ...survey, language: e.target.value })}
                        >
                            <option value="it">Italiano</option>
                            <option value="en">Inglese</option>
                        </Form.Select>

                        <br />

                        <label htmlFor="survey-desc">Descrizione :</label>
                        <br />
                        <textarea
                            id="survey-desc"
                            name="survey-desc"
                            value={survey?.description || ""}
                            onChange={(e) =>
                                setSurvey(() => {
                                    return { ...survey, description: e.target.value };
                                })
                            }
                            rows="4"
                            cols="50"
                            className="textarea"
                        ></textarea>
                        <br />
                    </form>
                </Modal.Body>
                <Modal.Footer className={`${data?._id ? 'd-flex justify-content-between' : ''}`}>
                    {(data?._id && (
                        <div className="left-btns">
                            <ConfirmButton
                                className="me-5"
                                label="Ellimina Survey"
                                danger={true}
                                action={handleDelete}
                                data={data}
                            />
                        </div>
                    )) || <></>}
                    <div className="right-btns">
                        <button
                            className="cancel-btn  mx-3"
                            onClick={() => setShowCloseConfirmation(true)}
                        >
                            Annulla
                        </button>
                        <button type="button" className="main-btn" onClick={handleSubmit} disabled={!survey?.name}>
                            {(data?._id && "Aggiorna") || "Aggiungi"}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>

            {/* Alert di conferma chiusura modale senza salvare possibili modifiche */}

            {showCloseConfirmation && (
                <CloseModal show={showCloseConfirmation}
                    onConfirmClose={handleConfirmClose}
                    onDontClose={() => setShowCloseConfirmation(false)}
                />
            )}


        </>
    );
};

export default SurveyModal;
