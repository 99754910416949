import React from "react";

const Header = () => {
  return (
    <div className="header bg-color main-center">
      <div className="logo">
        <img src="/assets/images/logo/vitamatch.png" alt="" />
      </div>
    </div>
  );
};

export default Header;
