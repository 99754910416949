import request from '../request';

const askToLogin = async (payload) => {

    const options = {
        url: `/login`,
        method: 'POST',
        payload: payload,
        token: null,
        mock: null
    }

    return await request({...options});

};

export default askToLogin;
