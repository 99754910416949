import request from '../request';

const askToSignup = async (payload) => {

    const options = {
        url: `/signup`,
        method: 'POST',
        payload: payload,
        token: null,
        mock: null
    }

    return await request({...options});

};

export default askToSignup;
