import { useState } from "react";

const LoadingButton = ({ label = 'button', trigger = null, payload = null, setter = null, template = null, icon = false }) => {

  const [loading, setLoading] = useState(false);

  const handleClick = async () => {

    if (trigger) {
      setLoading(() => true);
      const response = payload ? await trigger(payload) : await trigger();
      if (setter) {
        setter(response);
      }
      setLoading(() => false);
    }

  }

  const CustomTemplate = () => {

    return (
      template
    )
  }

  return (
    <>
      {
        loading && (
          icon && (<div className="spinner-border loading-spinner-icon" role="status"></div>) ||
          (<button type="button" className="main-btn">
            <div className="spinner-border loading-spinner" role="status"></div>
          </button>
          )
        ) || (
          template && (
            <span className="w-100" onClick={() => handleClick()}>
              <CustomTemplate />
            </span>
          ) || (<button className="btn btn-primary w-100" onClick={() => handleClick()}>{label}</button>)
        )
      }
    </>
  );
};

export default LoadingButton;
