import { Link } from "react-router-dom";
import axios from "axios";
import Header from "../components/Header";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import toast, { Toaster } from "react-hot-toast";
import LoadingButton from "../components/LoadingButton";
import askToSignup from "../services/collection/askToSignup";
import { Fade } from "react-awesome-reveal";

const SignUp = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("TotemVitaMatchTOKEN")) {
      navigate("/survey");
    }
  }, [localStorage]);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [validate, setValidate] = useState(false);

  const [matchingPassword, setMatchingPassword] = useState(true);

  const [loading, setLoading] = useState(false);

  const [hide, setHide] = useState(true);

  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const passwordRegex =
    /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_.;:'*#$%&~`\-_=+><|"'])(?=.*[a-z]).{8,}$/;

  useEffect(() => {
    if (
      //  emailRegex.test(email) &&
      passwordRegex.test(password) &&
      password === confirmPassword
    ) {
      setValidate(true);
    } else setValidate(false);
  }, [email, password, confirmPassword]);

  useEffect(() => {
    if (confirmPassword?.length > 0 && password !== confirmPassword) {
      setMatchingPassword(false);
    } else setMatchingPassword(true);
  }, [confirmPassword, password]);

  const handleSignup = async () => {

    setLoading(true);

    const response = await askToSignup({ email: email, password: password, });
    const status = response?.notify?.status;
    const message = response?.message;

    if (status === 201) {
      toast?.success(message);
      setTimeout(() => {
        navigate("/");
      }, 3000);
    } else {
      toast?.error(message);
      setLoading(false);
    }

  };

  return (
    <Fade cascade>
      <div className="login ">
        <Header />
        <div className="container">
          <div className="row">
            <div className="col-xs-11 col-sm-11 col-md-8 col-lg-7 col-xl-4 text-center">
              <h4 className="mb-5 text-center">Registra un totem</h4>
              <form>
                <div className="form-outline mb-5">
                  <div className="mb-4">
                    <span className="password-requirements text-left">
                      (*) La password deve contenere almeno 8 caratteri, una
                      lettera maiuscola, un numero ed un carattere speciale.
                    </span>
                  </div>
                  <input
                    type="email"
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    placeholder="Email"
                    className="form-input input"
                  />
                </div>

                <div className="form-outline mb-4">
                  <div className="d-flex">
                    <input
                      type={hide ? "password" : "text"}
                      name="password"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      placeholder="Password"
                      className="form-input input"
                    />
                    {(hide && (
                      <VisibilityOffIcon
                        onClick={() => setHide(false)}
                        className="eye-icon"
                      />
                    )) || (
                        <VisibilityIcon
                          onClick={() => setHide(true)}
                          className="eye-icon"
                        />
                      )}
                  </div>
                </div>
                <div className="form-outline mb-5">
                  <div className="d-flex">
                    <input
                      type={hide ? "password" : "text"}
                      name="conferma password"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      value={confirmPassword}
                      placeholder="Conferma password"
                      className="form-input input"
                    />
                    {(hide && (
                      <VisibilityOffIcon
                        onClick={() => setHide(false)}
                        className="eye-icon"
                      />
                    )) || (
                        <VisibilityIcon
                          onClick={() => setHide(true)}
                          className="eye-icon"
                        />
                      )}
                  </div>

                  {!matchingPassword ? (
                    <div className="invalid-container mt-4">
                      <span className="invalid-message">
                        Le password non coincidono!
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>


                <div className="col mb-5">
                  <Link to="/login" className="link">
                    Hai già un account? <span>Accedi</span>
                  </Link>
                </div>


                <LoadingButton template={<button disabled={!validate || loading} type="button" className={`main-btn  ${!validate || loading ? "disabled-btn" : ""}`}>Procedi</button>} trigger={handleSignup} />
              </form>
            </div>
          </div>
        </div>
      </div>
      <Toaster position="top-left" reverseOrder={false} />
    </Fade>
  );
};

export default SignUp;
