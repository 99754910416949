import Header from "../components/Header";

const Credits = () => {
  return (
    <div className="login ">
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-xs-11 col-sm-11 col-md-6 text-center">
            <div className="section bg-color main-center">
              <div className="article logo">
                <a href="https://www.devben.app">
                  <img src="/assets/images/benito-massidda.png" alt="" />
                  <h1 className="fs-5 my-3 ls-md">
                    Created by dev<strong>Ben</strong>
                  </h1>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Credits;
