
const getNotify = (status, url) => {
  switch (status) {
    case 200:
      return {
        notify: {
          type: "success",
          message: "ok",
          status: status,
          url,
        },
      };
    case 418:
      return {
        notify: {
          type: "warn",
          message: "Risposta mockata",
          status: status,
          url,
        },
      };
    case 500:
      return {
        notify: {
          type: "error",
          message: "Errore server",
          status: status,
          url,
        },
      };
    case 404:
      return {
        notify: {
          type: "error",
          message: "Risorsa non trovata",
          status: status,
          url,
        },
      };
    default:
      return {
        notify: {
          type: "error",
          message: `Errore ${status} ${url}`,
          status: status,
          url,
        },
      };
  }
};

const request = async ({ method, payload, mock, url }) => {

  const baseUrl = process.env.REACT_APP_API_URL;
  const tokenName = process.env.REACT_APP_TOKEN;

  const token = localStorage.getItem(tokenName) || null;
  
  const headers = {
    "Content-Type": "application/json",
    Authorization: token,
  };

  let options = {
    method,
    headers,
  };

  if (payload) {
    options["body"] = JSON.stringify(payload);
  }

  try {
    const response = await fetch(`${baseUrl}${url}`, options);
    const status = response?.status;
    const data = await response.json();
    const notify = getNotify(status, url);

    if (!status) {
      throw { ...data, ...notify, status }; // Aggiungi lo status all'oggetto di errore
    }

    // MOCKS
   if (status === 418) {
   //if (status === 200) {
      const notify = getNotify(status, url);
      return { ...mock, ...notify, status };
    }

    if (status === 200) {
      return {data, ...notify };
    } else {
      throw { ...data, ...notify, status }; // Aggiungi lo status all'oggetto di errore
    }
  } catch (error) {
    console.log(error);
    return error;
  }
};

export default request;

