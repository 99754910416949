import { useEffect, useState } from "react";

import { Fade } from "react-awesome-reveal";

import retrieveTotems from "../services/collection/totem/retrieveTotems";
import retrieveSurveys from "../services/collection/surveys/retrieveSurveys";

import TotemModal from "./modals/TotemModal";
import SearchBar from "./SearchBar";
import dateFormater from "../services/functions/dateFormater";
import { Toaster } from "react-hot-toast";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const TotemTable = () => {
  const [showModal, setShowModal] = useState(false);
  const [totems, setTotems] = useState([]);
  const [currentTotem, setCurrentTotem] = useState(null);
  const [filteredResults, setFilteredResults] = useState([]);
  const [search, setSearch] = useState("");
  // eslint-disable-next-line
  const [selectedSurveyNames, setSelectedSurveyNames] = useState({});


  const handleTotems = async () => {
    const totems = await retrieveTotems();
    const surveys = await retrieveSurveys();
    setSurveys(() => surveys?.data);
    setTotems(() => totems?.data);
    setFilteredResults(() => totems?.data);
  };

  const handleEditTotem = (totem) => {
    setCurrentTotem(() => totem);
    setShowModal(() => true);
  };

  const findSurvayName = (id) => {
    const survey = surveys?.find(survey => survey?._id === id) || '';
    return survey?.name
  }

  useEffect(() => {
    const filteredItems = totems?.filter(
      (totem) =>
        totem?.email?.toLowerCase()?.includes(search?.toLowerCase()) ||
        totem?.description?.toLowerCase()?.includes(search?.toLowerCase())
    );
    setFilteredResults(filteredItems);
    // eslint-disable-next-line
  }, [search]);

  //Gestione api survey list map
  const [surveys, setSurveys] = useState([]);

  const handleSurveys = async () => {
    const response = await retrieveSurveys();
    setSurveys(() => response?.data);
  };

  const handleSelectSurveyName = (totemId, surveyName) => {
    setSelectedSurveyNames((prevSelectedSurveyNames) => ({
      ...prevSelectedSurveyNames,
      [totemId]: surveyName,
    }));
  };


  useEffect(() => {
    handleTotems();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    handleTotems();
    handleSurveys()
  }, [showModal]);



  return (
    <>
      <div className="container bg-color">
        <Fade>
          <div className="row">
            <div className="table-header py-3">
              <h3 className="list-title">Lista Totems</h3>
            </div>
            <div className="table-navbar col-12">


              <div className="right-section col-12">
                <div className="search-add">
                  <SearchBar searchGetter={search} searchSetter={setSearch} />
                </div>
                {!search && (
                  <div className="container-add">
                    <button
                      type="button"
                      className="main-btn add-icon"
                      onClick={() => handleEditTotem(true)}
                    >
                      <img src="/assets/svg/add-circle.svg" alt="" />
                    </button>
                  </div>
                )}

              </div>
            </div>
            <div className="table-container">
              {(filteredResults?.length === 0 && (
                <div className="no-results">
                  <h5 className="text-center mt-5 text-muted">
                    Nessun dato trovato
                  </h5>
                </div>
              )) || (
                  <table className="table table-responsive table-borderless">
                    <thead>
                      <tr>
                        <th colSpan={1}>#</th>
                        <th colSpan={2} >Nome</th>
                        <th colSpan={4} >Descrizione</th>
                        <th colSpan={3} >Survey associato (IT)</th>
                        <th colSpan={3} >Survey associato (EN)</th>
                        <th colSpan={2}>Scadenza contratto</th>
                        <th colSpan={1} >Azione</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredResults?.map((totem, index) => (
                        <tr key={totem?._id}>
                          <td colSpan={1}>{index + 1}</td>
                          <td colSpan={2}>
                            {(totem?.email?.length > 30 &&
                              `${totem?.email?.slice(0, 30)} ...`) ||
                              totem?.email}
                          </td>
                          <td colSpan={4}>
                            {(totem?.description?.length > 50 &&
                              `${totem?.description?.slice(0, 50)} ...`) ||
                              totem?.description}
                          </td>
                          <td colSpan={3}>{(totem?.survey && totem?.survey.length > 30) ? `${findSurvayName(totem?.survey).slice(0, 30)} ...` : findSurvayName(totem?.survey)}</td>
                          <td colSpan={3}>{(totem?.surveyEN && totem?.surveyEN.length > 30) ? `${findSurvayName(totem?.surveyEN).slice(0, 30)} ...` : findSurvayName(totem?.surveyEN)}</td>
                          <td colSpan={2}>{dateFormater(totem?.contractExpirationDate)}</td>
                          <td colSpan={1} onClick={() => handleEditTotem(totem)}>
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="tooltip-edit">Modifica</Tooltip>}
                          >
                            <img
                              className="setting"
                              src="/assets/svg/edit.svg"
                              alt=""
                              style={{ width: "20px", height: "20px" }}
                            />
                          </OverlayTrigger>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
            </div>
          </div>
        </Fade>
      </div>

      {/* Modale di aggiungimento totem*/}

      <TotemModal
        show={{ getter: showModal, setter: setShowModal }}
        data={currentTotem}
        surveys={surveys}
        onSelectSurveyName={handleSelectSurveyName}
      />
      <Toaster position="top-left" reverseOrder={false} />

    </>
  );
};

export default TotemTable;
