import request from "../../request";

const getSuggestions = async () => {
  const options = {
    url: `/suggestions`,
    method: "GET",
    payload: null,
  };

  return await request({ ...options });
};

export default getSuggestions;
