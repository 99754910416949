import request from '../../request';

const addTotem = async (totem) => {

    const options = {
        url: `/signup`,
        method: 'POST',
        payload: totem
    }

    return await request({...options});

};

export default addTotem;
