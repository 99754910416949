import request from '../../request';

const deleteTotem = async (totem) => {

    const options = {
        url: `/users/${totem?._id}`,
        method: 'DELETE',
    }

    return await request({...options});

};

export default deleteTotem;
