import React from "react";
import { Modal } from "react-bootstrap";

const ImagePreviewModal = ({ show, onHide, imageUrl, title }) => {
  const handleDownload = async () => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = `${title}.jpg`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Errore durante il download dell'immagine:", error);
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <img src={imageUrl} alt="Preview" className="img-fluid preview-image" />
      </Modal.Body>
      <Modal.Footer>
        <div className="right-btn ">
          <button type="button" className="main-btn" onClick={handleDownload}>
            <i className="bi bi-download"></i> Scarica
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ImagePreviewModal;
