import request from "../../request";

const addSurvey = async (survey) => {
  const options = {
    url: `/surveys`,
    method: "POST",
    payload: survey,
  };

  return await request({ ...options });
};

export default addSurvey;
