import { useState } from "react"

const ConfirmButton = ({ className, label = 'Conferma', danger = false, action = null, data = null }) => {

    const [confirm, setConfirm] = useState(false);

    const confirmAction = () => {
        setConfirm(false);
        if (action) {
            return action(data);
        }
    }



    return (
        <div className={className}>
            {
                confirm && (
                    <>
                        <button className="cancel-btn confirm-btm mx-1" onClick={() => setConfirm(false)}>
                            <img src="/assets/svg/close.svg" style={{ width: "21px", height: "21px" }} alt="" />
                        </button>
                        <button type="button" className="main-btn confirm-btn mx-1" onClick={() => confirmAction()}>
                            <img src="/assets/svg/check.svg" style={{ width: "21px", height: "21px" }} alt="" />
                        </button>
                    </>
                ) || (
                    <button className={`${danger && 'cancel' || 'main'}-btn`} onClick={() => setConfirm(true)}>
                        {label}
                    </button>
                )
            }
        </div>
    )
}

export default ConfirmButton