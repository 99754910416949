import request from '../../request';

const editTotem = async (totem) => {

    const options = {
        url: `/users/${totem?._id}`,
        method: 'PUT',
        payload: totem
    }

    return await request({...options});

};

export default editTotem;
