import Header from "../components/Header";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router";
import askToLogin from "../services/collection/askToLogin";
import LoadingButton from "../components/LoadingButton";
import { Fade } from "react-awesome-reveal";
import { useLanguage } from "../services/functions/LanguageProvider";

const Login = () => {
  const navigate = useNavigate();
  const { translations, language } = useLanguage();

  useEffect(() => {

    const token = localStorage?.getItem("TotemVitaMatchTOKEN");

    if (token !== 'undefined' && token !== null) {
      navigate("/survey");
    }
    // eslint-disable-next-line
  }, []);

  const [hide, setHide] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validate, setValidate] = useState("");
  // eslint-disable-next-line
  const [invalidCredentials, setInvalidCredentials] = useState(false);

  useEffect(() => {
    if (email?.length > 4 && password?.length > 3) {
      setValidate(true);
    } else setValidate(false);
  }, [email, password, validate]);

  const handleLogin = async () => {
    const response = await askToLogin({ email: email, password: password });
    // eslint-disable-next-line
    const status = response?.notify?.status;
    const message = response?.message;

    if (response?.data?.token) {
      localStorage.setItem("TotemVitaMatchTOKEN", response?.data?.token);
      localStorage.setItem("TotemVitaMatchSURVEY", JSON.stringify(response?.data?.survey?.data));
      localStorage.setItem("TotemVitaMatchPRODUCTS", JSON.stringify(response?.data?.survey?.products));
      navigate("/survey");
    } else {
      toast?.error(message);
    }

  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleLogin();
    }
  };



  return (
    <Fade cascade>
      <div className="login flex-column">
        <Header />
        <div className="container">
          <div className="row">
            <div className="text-center">
              <h4 className="mb-5 text-center">{translations[language]?.common?.login}</h4>

              <form onKeyDown={handleKeyDown}>
                <div className="form-outline mb-5 ">
                  <input
                    type="text"
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    placeholder="Nome Totem"
                    className="form-input input"
                  />
                </div>

                <div className="form-outline mb-3 pb-3">
                  <div className="d-flex ">
                    <input
                      type={hide ? "password" : "text"}
                      name="password"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      placeholder="Password"
                      className="form-input input"
                    />
                    {(hide && (
                      <VisibilityOffIcon
                        onClick={() => setHide(false)}
                        className="eye-icon"
                      />
                    )) || (
                        <VisibilityIcon
                          onClick={() => setHide(true)}
                          className="eye-icon"
                        />
                      )}
                  </div>
                </div>


                <div className="mb-5">
                  <a href="#" className="link">
                  {translations[language]?.common?.forgotPassword}
                  </a>
                </div>

                <LoadingButton
                  template={
                    <button type="button" className="main-btn">
                      {translations[language]?.common?.enter}
                    </button>
                  }
                  trigger={handleLogin}
                />
                {invalidCredentials && !validate ? (
                  <div className="invalid-container">
                    <span className="mt-4 invalid-message">
                    {translations[language]?.common?.warningCredentials}
                    </span>
                  </div>
                ) : (
                  <></>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
      <Toaster position="top-left" reverseOrder={false} />
    </Fade>
  );
};

export default Login;
