import request from "../../request";

const uploadSurveyJSON = async (survey) => {

  const {_id, ...other} = survey

  const options = {
    url: '/surveys/upload',
    method: 'POST',
    payload: {...other},
  };

  return await request({ ...options });
};

export default uploadSurveyJSON;
