import request from "../../request";

const retrieveResults = async () => {
  const options = {
    url: `/results`,
    method: "GET",
  };

  return await request({ ...options });
};

export default retrieveResults;
