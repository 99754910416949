import Header from "../components/Header";
import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router";
import { Fade } from "react-awesome-reveal";

import LoadingButton from "../components/LoadingButton";

const LoginAdmin = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("TotemVitaMatchAdminID")) {
      navigate("/admin-login");
    }
  }, [localStorage]);

  const [password, setPassword] = useState("");
  const [validate, setValidate] = useState("");
  const [invalidCredentials, setInvalidCredentials] = useState(false);

  const handleCheck = () => {
    // const response = await askToCheck({ email: email, password: password });
    // const status = response?.notify?.status;
    // const message = response?.message;

    // if (status === 200) {
    //   localStorage.setItem("TotemVitaMatchAdminID", response?.data?.token);
    //   navigate("/login-admin");
    // } else {
    //   toast?.error(message);
    // }
    navigate("/admin");
  };

  return (
    <Fade cascade>
      <div className="login flex-column">
        <Header />
        <div className="container">
          <div className="row">
            <div className="col-xs-11 col-sm-11 col-md-8 col-lg-7 col-xl-4 text-center">
              <h4 className="mb-5 text-center">Conferma la tua password</h4>

              <form>
                <div className="form-outline mb-2 p-3">
                  <div className="d-flex ">
                    <input
                      type="password"
                      name="password"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      autoComplete="off"
                      placeholder="Password"
                      className="form-input input"
                    />
                  </div>
                </div>


                <div className="col mb-5">
                  <a href="#" className="link">
                    Password dimenticata?
                  </a>
                </div>

                <LoadingButton
                  template={
                    <button
                      type="button"
                      className="main-btn"
                      onClick={handleCheck}>
                      Verifica
                    </button>
                  }
                />
                {invalidCredentials && !validate ? (
                  <div className="invalid-container">
                    <span className="mt-4 invalid-message">
                      Inserire delle credenziali valide.
                    </span>
                  </div>
                ) : (
                  <></>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
      <Toaster position="top-left" reverseOrder={false} />
    </Fade>
  );
};

export default LoginAdmin;
