import React, { useCallback, useEffect, useRef, useState } from "react";
import { Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import CloseModal from "./CloseModal";
import editSurvey from "../../services/collection/surveys/editSurvey";
import retrieveSurvey from "../../services/collection/surveys/retrieveSurvey";
import Select from "react-select";
import getSuggestions from "../../services/collection/surveys/getSuggestions";

const SettingsSurveyModal = ({
  showModal,
  currentSurvey,
  surveyQuestionData,
}) => {
  const [survey, setSurvey] = useState(currentSurvey);

  //mini modale richiesta conferma chiusura modifica
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);

  //gestione modalità modifica survey item/domande e risposte
  const [editItemIndex, setEditItemIndex] = useState(null);
  const [isRemovingAnswer, setIsRemovingAnswer] = useState(false);

  //State per tracciare l'indice della risposta selezionata
  const [selectedAnswer, setSelectedAnswer] = useState(null);

  //gestione spostamento su e giù di ciascun survey item
  const [movingItemIndex, setMovingItemIndex] = useState(null);
  const [maxMoveIndex, setMaxMoveIndex] = useState(
    surveyQuestionData?.getter.length - 1
  );
  const scrollable = useRef(null);
  const moveButtonsRefs = useRef([]);
  const suveryItemRefs = useRef([]);

  //se in modalità modifica nascondere modo di riordinare le domande
  const [isEditMode, setIsEditMode] = useState(false);

  const [suggestions, setSuggestions] = useState([]);

  const handleSuggestions = useCallback(async () => {
    const response = await getSuggestions();
    const suggestions = response?.data?.map((item) => ({
      value: item,
      label: item,
    }));
    setSuggestions(suggestions || []);
  }, [selectedAnswer]);

  useEffect(() => {
    handleSuggestions();
  }, [handleSuggestions]);

  const questionCategory = [
    { value: "mind", label: "Mente" },
    { value: "bones", label: "Ossa" },
    { value: "digestion", label: "Digestione" },
    { value: "energy", label: "Energia" },
    { value: "hair", label: "Capelli" },
    { value: "heart", label: "Cuore" },
    { value: "defenses", label: "Difese" },
    { value: "skin", label: "Pelle" },
    { value: "sleep", label: "Sonno" },
    { value: "stress", label: "Stress" },
    { value: "weight", label: "Peso" },
    { value: "womenHealth", label: "Salute delle donne" },
    { value: "menHealth", label: "Salute degli uomini" },
    { value: "all", label: "Tutti" },
  ];

  const saveAndRetrieve = async (getter, setter) => {
    await editSurvey(getter);
    const retrieved = await retrieveSurvey(getter?._id);
    setter(() => retrieved?.data);
  };

  useEffect(() => {
    setMaxMoveIndex(surveyQuestionData?.getter.length - 1);
  }, [surveyQuestionData?.getter]);

  useEffect(() => {
    setSurvey(() => currentSurvey);
  }, [currentSurvey]);

  //switch case di colori per ogni lettera
  const getColorForLetter = (letter) => {
    switch (letter) {
      case "a":
        return "#f56765";
      case "b":
        return "#8dbcea";
      case "c":
        return "#88bdab";
      case "d":
        return "#f1ea86";
      case "e":
        return "#f19c64";
      default:
        return "#f56765";
    }
  };

  const handleEditModeIndex = (index) => {
    setEditItemIndex(index); // Salva l'indice dell'elemento in modifica
    setIsEditMode(true);
    setSelectedAnswer(null);

    suveryItemRefs.current[index].focus();
  };

  //funzione per settare quanto selezionato dalla select categoria dper ogni domanda
  const handleSelectChange = (question, categoryValue) => {
    const category = questionCategory?.find(
      (item) => item.value === categoryValue
    );

    const updatedQuestions = survey?.questions?.map((questionItem) => {
      if (questionItem?._id === question?._id) {
        return {
          ...question,
          questionCategory: category,
        };
      } else {
        return questionItem;
      }
    });

    const updatedSurvey = {
      ...survey,
      questions: [...updatedQuestions],
    };

    setSurvey(updatedSurvey);
  };

  const handleQuestionAttributeChange = (question, attribute, value) => {
    const updatedQuestions = survey?.questions?.map((questionItem) => {
      if (questionItem?._id === question?._id) {
        return {
          ...question,
          [attribute]: value,
        };
      } else {
        return questionItem;
      }
    });

    const updatedSurvey = {
      ...survey,
      questions: [...updatedQuestions],
    };

    setSurvey(updatedSurvey);
  };

  //Update Survey
  const handleUpdate = async () => {
    await editSurvey(survey);
  };

  //funzione per aggiungere una nuova domanda e risposte al survey scelto
  const handleAddNewQuestion = async () => {
    const newSurveyQuestion = {
      questionType: "a",
      questionText: "Nuova domanda",
      possibleAnswers: [
        {
          answerType: "a",
          answerText: "Risposta a",
          recommendedProducts: [],
          notRecommendedProducts: [],
        },
        {
          answerType: "b",
          answerText: "Risposta b",
          recommendedProducts: [],
          notRecommendedProducts: [],
        },
        {
          answerType: "c",
          answerText: "Risposta c",
          recommendedProducts: [],
          notRecommendedProducts: [],
        },
        {
          answerType: "d",
          answerText: "Risposta d",
          recommendedProducts: [],
          notRecommendedProducts: [],
        },
        {
          answerType: "e",
          answerText: "Risposta e",
          recommendedProducts: [],
          notRecommendedProducts: [],
        },
      ],
    };

    const updatedSurvey = {
      ...survey,
      questions: [...survey?.questions, newSurveyQuestion],
    };

    setSelectedAnswer(null);

    await saveAndRetrieve(updatedSurvey, setSurvey);

    scrollable.current.scrollTop = 0;
  };

  // Funzione per gestire la modifica di una domanda
  const handleQuestionChange = async (question, questionText) => {
    let questions = survey?.questions?.map((questionItem) => {
      if (questionItem?._id === question?._id) {
        return {
          ...question,
          questionText: questionText,
        };
      } else {
        return questionItem;
      }
    });

    const updatedSurvey = {
      ...survey,
      questions: questions,
    };

    setSurvey(() => updatedSurvey);
  };

  // Funzione per gestire la modifica di una risposta
  const handleAnswerChange = (question, answer, newAttribute, newValue) => {
    const updatedQuestions = survey?.questions?.map((questionItem) => {
      if (questionItem?._id === question?._id) {
        const possibleAnswers = questionItem?.possibleAnswers?.map(
          (answerItem) => {
            if (answerItem?._id === answer?._id) {
              return {
                ...answer,
                [newAttribute]: newValue,
              };
            } else {
              return answerItem;
            }
          }
        );

        return {
          ...question,
          possibleAnswers: possibleAnswers,
        };
      } else {
        return questionItem;
      }
    });

    const updatedSurvey = {
      ...survey,
      questions: [...updatedQuestions],
    };

    setSelectedAnswer(null);
    setSurvey(updatedSurvey);
  };

  //Funzione per aggiungere una risposta quando ve ne sono meno di 5 (numero max)
  const handleAddAnswer = (question) => {
    const newAnswer = {
      answerType: "a",
      answerText: "Nuova risposta",
      recommendedProducts: [],
      notRecommendedProducts: [],
    };

    const updatedQuestions = survey?.questions?.map((questionItem) => {
      if (questionItem?._id === question?._id) {
        const possibleAnswers = [...questionItem?.possibleAnswers, newAnswer];

        return {
          ...question,
          possibleAnswers: possibleAnswers,
        };
      } else {
        return questionItem;
      }
    });

    const updatedSurvey = {
      ...survey,
      questions: [...updatedQuestions],
    };

    setSurvey(updatedSurvey);
    setSelectedAnswer(null);
  };

  const handleRemoveAnswer = (question, answer) => {
    if (isRemovingAnswer) {
      return; // Evita ulteriori eliminazioni finché l'operazione non è completata
    }

    setIsRemovingAnswer(true); // Inizia l'operazione di eliminazione

    const updatedQuestions = survey?.questions?.map((questionItem) => {
      if (questionItem?._id === question?._id) {
        const possibleAnswers = questionItem?.possibleAnswers?.filter(
          (answerItem) => answerItem?._id !== answer?._id
        );

        return {
          ...question,
          possibleAnswers: possibleAnswers,
        };
      } else {
        return questionItem;
      }
    });

    const updatedSurvey = {
      ...survey,
      questions: [...updatedQuestions],
    };

    setSurvey(updatedSurvey);

    setIsRemovingAnswer(false); // Completa l'operazione di eliminazione
  };

  // Funzione per gestire il salvataggio delle modifiche eseguite nelle
  //domande e risposte per ogni survey item ( onClick su ✔)
  const handleSaveChanges = () => {
    setEditItemIndex(null);
    setIsEditMode(false);
    setSelectedAnswer(null);
  };

  //GESTIONE UP AND DOWN PER SPOSTARE SU E GIU' I SURVEY ITEM
  const handleMoveItem = (currentIndex, newIndex) => {
    if (
      currentIndex === newIndex ||
      newIndex < 0 ||
      newIndex >= survey?.questions?.length
    ) {
      return; // Non fare nulla se currentIndex è uguale a newIndex o newIndex è fuori dai limiti
    }

    const updatedSurveyQuestionData = [...survey?.questions];
    const temp = updatedSurveyQuestionData[currentIndex];
    updatedSurveyQuestionData[currentIndex] =
      updatedSurveyQuestionData[newIndex];
    updatedSurveyQuestionData[newIndex] = temp;

    surveyQuestionData?.setter(updatedSurveyQuestionData);
    setSurvey({ ...survey, questions: updatedSurveyQuestionData });
    setMovingItemIndex(newIndex);

    if (suveryItemRefs.current[newIndex]) {
      suveryItemRefs.current[newIndex].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });

      // Imposta il focus sull'elemento selezionato dopo il movimento
      suveryItemRefs.current[newIndex].focus();
    }
  };

  const handleMoveItemUp = (currentIndex) => {
    handleMoveItem(currentIndex, currentIndex - 1);
  };

  const handleMoveItemDown = (currentIndex) => {
    handleMoveItem(currentIndex, currentIndex + 1);
  };

  //funzione di conferma elliminazione survey item/survey domanda
  const handleDelete = (item) => {
    const updatedQuestions = survey?.questions?.filter(
      (question) => question?._id !== item?._id
    );

    const updatedSurvey = {
      ...survey,
      questions: [...updatedQuestions],
    };
    setSurvey(updatedSurvey);

    scrollable.current.scrollTop = 0;
  };

  //Funziona di salvataggio modifiche totali onClick sul button "Salva" della modale.
  const handleSaveAndCloseModal = async () => {
    await handleUpdate();

    setMovingItemIndex(null);
    showModal.setter(false);
  };

  //funzione per la modalina che appare 'Le tue modifiche non verrano salvate. Sei sicur* di voler chiudere?'
  const handleConfirmClose = () => {
    showModal?.setter(false);
    setShowCloseConfirmation(false);
    setMovingItemIndex(null);
    setEditItemIndex(null);
  };

  const handleTag = (question, tagList, attribute, removeTag) => {
    // const value = tagList?.current?.value?.toLowerCase()?.replace(/\s/g, " ");
    // let newTagList = [];

    // if (removeTag) {
    //   newTagList = tagList?.filter((item) => item !== removeTag);
    // } else {
    //   if (value?.length) {
    //     newTagList = !tagList?.includes(value) ? [...tagList, value] : tagList;
    //   } else {
    //     newTagList = tagList;
    //   }
    // }

    const answer = {
      ...selectedAnswer,
      [attribute]: tagList,
    };

    setSelectedAnswer(() => answer);

    if (tagList.current) {
      tagList.current.value = "";
    }

    const updatedQuestions = survey?.questions?.map((questionItem) => {
      if (questionItem?._id === question?._id) {
        const updatedAnswers = questionItem?.possibleAnswers?.map(
          (answerItem) => {
            if (answerItem?._id === selectedAnswer?._id) {
              return answer;
            } else {
              return answerItem;
            }
          }
        );

        return {
          ...question,
          possibleAnswers: updatedAnswers,
        };
      } else {
        return questionItem;
      }
    });

    const updatedSurvey = {
      ...survey,
      questions: [...updatedQuestions],
    };

    setSurvey(updatedSurvey);
  };

  return (
    <>
      <Modal
        show={showModal?.getter}
        backdrop="static"
        keyboard={false}
        className="modal-edit modal-edit-survey"
      >
        <Modal.Header>
          <div className="modal-header-titles">
            <h4 className="m-0">
              Impostazioni : <i>{survey ? survey?.name : "Survey"}</i>
            </h4>
            <p className="m-0">
              {survey
                ? survey?.description
                : "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eos ad laboriosam corporis accusamus aliquid hic dolor pariatur dignissimos, dolorem, numquam animi expedita beatae quo."}
            </p>
          </div>
        </Modal.Header>
        <Modal.Body ref={scrollable}>
          {survey?.questions?.length > 0 ? (
            <ul className="suvery-container-list my-4 px-3">
              {survey?.questions?.map((question, index) => (
                <li
                  key={index}
                  ref={(el) => (suveryItemRefs.current[index] = el)}
                  className={`suvery-item ${
                    index === movingItemIndex ? "moving" : ""
                  }`}
                >
                  {editItemIndex === index ? (
                    <>
                      {/* Render the inputs and edit controls */}
                      <div className="leftside-survey-item col-1">
                        <select
                          value={question?.questionType}
                          onChange={(e) =>
                            handleQuestionAttributeChange(
                              question,
                              "questionType",
                              e.target.value
                            )
                          }
                          className={`mx-4 select-letters letter-${question?.questionType?.toLowerCase()}`}
                        >
                          <option value="a" className="letter-a ">
                            A
                          </option>
                          <option value="b" className="letter-b">
                            B
                          </option>
                          <option value="c" className="letter-c">
                            C
                          </option>
                          <option value="d" className="letter-d">
                            D
                          </option>
                          <option value="e" className="letter-e">
                            E
                          </option>
                        </select>
                      </div>

                      <div
                        className={`center-survey-item ${
                          selectedAnswer !== null ? "col-5" : "col-10"
                        }`}
                      >
                        <div className="survey-questions col-11">
                          <div className="question d-flex col-12 justify-content-between">
                            <div className="col-8">
                              <input
                                className="modal-question-input"
                                type="text"
                                placeholder="Aggiungi la tua domanda"
                                value={question?.questionText}
                                onChange={(e) =>
                                  handleQuestionChange(question, e.target.value)
                                }
                              />
                            </div>
                            <div className="col-3">
                              <Form.Select
                                aria-label="Seleziona una categoria"
                                className={`modal-question-input py-2 ${
                                  selectedAnswer !== null ? "px-2" : ""
                                }`}
                                value={question?.questionCategory?.value}
                                onChange={(e) =>
                                  handleSelectChange(question, e.target.value)
                                }
                              >
                                <option value="" disabled hidden>
                                  Categoria
                                </option>
                                {questionCategory.map((category, index) => (
                                  <option key={index} value={category?.value}>
                                    {category?.label}
                                  </option>
                                ))}
                              </Form.Select>
                            </div>
                          </div>

                          <ul className="colored-list ">
                            {question?.possibleAnswers?.map(
                              (answerItem, idx) => (
                                <li
                                  key={idx}
                                  className="single-answer d-flex align-items-center mb-2 px-2"
                                >
                                  <select
                                    className={`color-select letter-${answerItem?.answerType}`}
                                    aria-label="Seleziona"
                                    onChange={(e) =>
                                      handleAnswerChange(
                                        question,
                                        answerItem,
                                        "answerType",
                                        e.target.value
                                      )
                                    }
                                    value={answerItem?.answerType}
                                  >
                                    {["a", "b", "c", "d", "e"].map((letter) => (
                                      <option
                                        key={letter}
                                        value={letter}
                                        className={`letter-${letter.toLowerCase()}`}
                                      >
                                        <span
                                          style={{
                                            color: getColorForLetter(letter),
                                          }}
                                        >
                                          {letter}
                                        </span>
                                      </option>
                                    ))}
                                  </select>

                                  <input
                                    className=" modal-answers-input"
                                    type="text"
                                    placeholder="Aggiungi una risposta"
                                    value={answerItem?.answerText}
                                    onChange={(e) =>
                                      handleAnswerChange(
                                        question,
                                        answerItem,
                                        "answerText",
                                        e.target.value
                                      )
                                    }
                                    onClick={() =>
                                      setSelectedAnswer(() => answerItem)
                                    }
                                  />
                                  {question?.possibleAnswers?.length !== 1 && (
                                    <span
                                      className="mx-2"
                                      onClick={() =>
                                        handleRemoveAnswer(question, answerItem)
                                      }
                                      disabled={isRemovingAnswer}
                                    >
                                      <img
                                        src="/assets/svg/delete.svg"
                                        alt="Elimina risposta"
                                        width={"15px"}
                                      />
                                    </span>
                                  )}
                                </li>
                              )
                            )}

                            {question?.possibleAnswers?.length < 5 && (
                              <button
                                className="add-answer"
                                onClick={() => handleAddAnswer(question)}
                              >
                                Aggiungi una risposta
                              </button>
                            )}
                          </ul>
                        </div>

                        <div className="delete col-1 text-end">
                          <img
                            alt=""
                            onClick={() => handleDelete(question)}
                            src="/assets/svg/delete.svg"
                          />
                        </div>
                      </div>

                      {selectedAnswer !== null ? (
                        <div className="rightside-survey-item  col-5">
                          <h6 className="pt-4 answer-title">
                            {selectedAnswer?.answerText.length > 50
                              ? selectedAnswer?.answerText.slice(0, 50) + "..."
                              : selectedAnswer?.answerText}
                          </h6>
                          <span
                            className="close-section"
                            onClick={() => setSelectedAnswer(null)}
                          >
                            <img
                              src="/assets/svg/close.svg"
                              alt="Elimina risposta"
                            />
                          </span>

                          {console.log("selectdAnswer-->", selectedAnswer)}

                          <div className="row col-12 products">
                            <div className="recommended rightside-columns col-6">
                              <h6 className="mb-3">Raccomandati</h6>
                              <Select
                                value={selectedAnswer?.recommendedProducts?.map(
                                  (item) => ({
                                    value: item,
                                    label: item,
                                  })
                                )}
                                isMulti
                                name="recommendedFor"
                                options={suggestions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Seleziona opzioni..."
                                noOptionsMessage={() =>
                                  "Nessun opzione"
                                }
                                onChange={(selected) => {
                                  const recommendedForList = selected?.map(
                                    (item) => item.value
                                  );
                                  setSelectedAnswer({
                                    ...selectedAnswer,
                                    recommendedProducts: recommendedForList,
                                  });
                                  handleTag(
                                    question,
                                    recommendedForList,
                                    "recommendedProducts"
                                  );
                                }}
                              />
                            </div>

                            <div className="to-avoid rightside-columns col-6">
                              <h6 className="mb-3">Sconsigliati</h6>

                              <Select
                                value={selectedAnswer?.notRecommendedProducts?.map(
                                  (item) => ({
                                    value: item,
                                    label: item,
                                  })
                                )}
                                isMulti
                                name="notRecommendedFor"
                                options={suggestions}
                                className="basic-multi-select red-basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Seleziona opzioni..."
                                noOptionsMessage={() =>
                                  "Nessun opzione"
                                }
                                onChange={(selected) => {
                                  const notRecommendedForList = selected?.map(
                                    (item) => item.value
                                  );
                                  setSelectedAnswer({
                                    ...selectedAnswer,
                                    notRecommendedProducts:
                                      notRecommendedForList,
                                  });

                                  handleTag(
                                    question,
                                    notRecommendedForList,
                                    "notRecommendedProducts"
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      <div className="edit done col-1 text-center">
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip-edit">Conferma</Tooltip>
                          }
                        >
                          <img
                            src="/assets/svg/done.svg"
                            alt=""
                            onClick={() => handleSaveChanges()}
                          />
                        </OverlayTrigger>
                      </div>
                    </>
                  ) : (
                    <>
                      {/* Render the non-editable view */}
                      <div
                        className={`leftside-survey-item col-${
                          index === movingItemIndex ? "2" : "1"
                        }`}
                      >
                        {isEditMode ? null : (
                          <div
                            className={`move-btns ${
                              index === movingItemIndex
                                ? ""
                                : movingItemIndex !== null
                                ? "d-none"
                                : ""
                            }`}
                          >
                            <button
                              ref={(el) =>
                                (moveButtonsRefs.current[index] = el)
                              }
                              className={`up-btn move ${
                                index === 0 ? "disabled-btn" : ""
                              }`}
                              onClick={() => handleMoveItemUp(index)}
                              disabled={index === 0}
                            >
                              <img src="/assets/svg/arrow-up.svg" alt="" />
                            </button>
                            <button
                              ref={(el) =>
                                (moveButtonsRefs.current[index] = el)
                              }
                              className={`down-btn move ${
                                index === maxMoveIndex ? "disabled-btn" : ""
                              }`}
                              onClick={() => handleMoveItemDown(index)}
                              disabled={index === maxMoveIndex}
                            >
                              <img src="/assets/svg/arrow-down.svg" alt="" />
                            </button>
                          </div>
                        )}
                        <div
                          className={`letter-id-survey letter-${question?.questionType}`}
                        >
                          {question?.questionType}
                        </div>
                      </div>
                      <div
                        className={`center-survey-item ${
                          index === movingItemIndex ? "col-9" : "col-10"
                        }`}
                      >
                        <div className="survey-questions col-11">
                          <div className="question d-flex justify-content-between col-12">
                            <span
                              className="pb-3 col-9"
                              onClick={() =>
                                index !== movingItemIndex &&
                                handleEditModeIndex(index)
                              }
                              style={{
                                cursor:
                                  index !== movingItemIndex
                                    ? "pointer"
                                    : "default",
                              }}
                            >
                              {" "}
                              <b>{question?.questionText}</b>
                            </span>
                            {question?.questionCategory?.value && (
                              <span className="col-3 text-end">
                                Categoria:
                                <b> {question?.questionCategory?.label} </b>
                              </span>
                            )}
                          </div>
                          <div className="answers">
                            <ul className="colored-list">
                              {question?.possibleAnswers?.map(
                                (answerItem, idx) => (
                                  <div key={idx} className="d-flex answers-row">
                                    <li className="single-answer my-1">
                                      <span
                                        className={`colored-dots answerType-${answerItem?.answerType}`}
                                      ></span>
                                      {answerItem.answerText}
                                    </li>
                                    <div className="products-count">
                                      {answerItem?.recommendedProducts?.length >
                                        0 && (
                                        <span className="product-count recom-count">
                                          {
                                            answerItem?.recommendedProducts
                                              ?.length
                                          }
                                        </span>
                                      )}
                                      {answerItem?.notRecommendedProducts
                                        ?.length > 0 && (
                                        <span className="product-count not-recom-count">
                                          {
                                            answerItem?.notRecommendedProducts
                                              ?.length
                                          }
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                )
                              )}
                            </ul>
                          </div>
                        </div>

                        <div
                          className={`delete col-1 text-end ${
                            index === movingItemIndex ? "hidden" : ""
                          }`}
                        >
                          <img
                            onClick={() => handleDelete(question)}
                            src="/assets/svg/delete.svg"
                            alt=""
                          />
                        </div>
                      </div>
                      {index === movingItemIndex ? (
                        <div
                          className={`edit done col-1 text-center`}
                          onClick={() => {
                            setMovingItemIndex(null); // Questa riga chiuderà la selezione "moving"
                          }}
                        >
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="tooltip-edit">Conferma</Tooltip>
                            }
                          >
                            <img src="/assets/svg/done.svg" alt="" />
                          </OverlayTrigger>
                        </div>
                      ) : (
                        <div
                          className="edit col-1 text-center"
                          onClick={() => handleEditModeIndex(index)}
                        >
                          <img src="/assets/svg/edit.svg" alt="" />
                        </div>
                      )}
                    </>
                  )}
                </li>
              ))}
            </ul>
          ) : (
            <div className="text-center">
              <h5>Nessuna domanda presente.</h5>
              <button
                type="button"
                className="main-btn mt-2"
                onClick={handleAddNewQuestion}
              >
                Aggiungi
              </button>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between pt-4">
          <div className="left-btns">
            <button
              type="button"
              className="main-btn"
              onClick={handleAddNewQuestion}
            >
              Aggiungi una domanda
            </button>
          </div>
          <div className="right-btns">
            <button
              className="cancel-btn  mx-3"
              onClick={() => setShowCloseConfirmation(true)}
            >
              Annulla
            </button>
            <button
              type="button"
              className="main-btn"
              onClick={handleSaveAndCloseModal}
            >
              Salva
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Alert di conferma chiusura modale senza salvare possibili modifiche */}
      {showCloseConfirmation && (
        <CloseModal
          show={showCloseConfirmation}
          onConfirmClose={handleConfirmClose}
          onDontClose={() => setShowCloseConfirmation(false)}
        />
      )}
    </>
  );
};

export default SettingsSurveyModal;
