import { useState, useEffect } from "react";
import { Fade } from "react-awesome-reveal";
import SearchBar from "./SearchBar";
import SettingsSurveyModal from "./modals/SettingsSurveyModal";
import retrieveSurveys from "../services/collection/surveys/retrieveSurveys";
import SurveyModal from "./modals/SurveyModal";
import addSurvey from "../services/collection/surveys/addSurvey";
import uploadSurvey from "../services/collection/surveys/uploadSurveyJSON"; 
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import downloadSurveyJSON from "../services/collection/surveys/downloadSurveyJSON";

const downloadFile = (data, filename, type) => {
  const blob = new Blob([data], { type });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

const SurveyTable = () => {
  const [surveys, setSurveys] = useState([]);
  const [currentSurvey, setCurrentSurvey] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [showModalSettingsSurveys, setShowModalSettingsSurveys] = useState(
    false
  );

  const [search, setSearch] = useState("");
  const [filteredResults, setFilteredResults] = useState(surveys);

  useEffect(() => {
    const filteredItems = surveys?.filter(
      (survey) =>
        survey?.name?.toLowerCase()?.includes(search?.toLowerCase()) ||
        survey?.description?.toLowerCase()?.includes(search?.toLowerCase())
    );
    setFilteredResults(filteredItems);
    // eslint-disable-next-line
  }, [search]);

  const handleSurveys = async () => {
    const response = await retrieveSurveys();
    setSurveys(() => response?.data);
    setFilteredResults(() => response?.data);
  };

  const handleEditSurvey = (survey) => {
    setCurrentSurvey(() => survey);
    setShowModal(() => true);
  };

  const handleDuplicate = async (survey) => {
    const { _id, ...duplicated } = survey;

    const copiedSurvey = {
      ...duplicated,
      name: "copy of " + duplicated?.name,
    };

    await addSurvey(copiedSurvey);
    await handleSurveys();
  };

  const downloadJSON = async (survey) => {
    const json = await downloadSurveyJSON(survey);

    const date = new Date();
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}_${date
        .getHours()
        .toString()
        .padStart(2, '0')}-${date.getMinutes().toString().padStart(2, '0')}-${date
          .getSeconds()
          .toString()
          .padStart(2, '0')}`;
    const filename = `${survey.name}_${formattedDate}.json`;

    downloadFile(JSON.stringify(json.data, null, 2), filename, 'application/json');
  };

  const handleUploadJSON = async (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/json") {
      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          const json = JSON.parse(e.target.result);
          const date = new Date();
          const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
            .toString()
            .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}_${date
              .getHours()
              .toString()
              .padStart(2, '0')}-${date.getMinutes().toString().padStart(2, '0')}-${date
                .getSeconds()
                .toString()
                .padStart(2, '0')}`;
          const newSurveyName = `${json.name}_${formattedDate}`;
          json.name = newSurveyName;
          await uploadSurvey(json); // Assicurati di avere un servizio che gestisca l'upload
          await handleSurveys();
        } catch (error) {
          console.error("Error uploading JSON:", error);
        }
      };
      reader.readAsText(file);
    } else {
      alert("Please upload a valid JSON file.");
    }
  };

  const handleSettingsSurvey = (survey) => {
    setCurrentSurvey(() => survey);
    setShowModalSettingsSurveys(() => true);
  };

  useEffect(() => {
    handleSurveys();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    handleSurveys();
  }, [showModal, showModalSettingsSurveys]);

  const [surveyQuestionData, setSurveyQuestionData] = useState([
    {
      letterId: "a",
      question: "Lorem ipsum dolor sit amet?",
      answers: [
        {
          answerType: "a",
          answerText: "Risposta a",
          recommendedProducts: [],
          notRecommendedProducts: [],
        },
        {
          answerType: "b",
          answerText: "Risposta b",
          recommendedProducts: [],
          notRecommendedProducts: [],
        },
        {
          answerType: "c",
          answerText: "Risposta c",
          recommendedProducts: [],
          notRecommendedProducts: [],
        },
        {
          answerType: "d",
          answerText: "Risposta d",
          recommendedProducts: [],
          notRecommendedProducts: [],
        },
        {
          answerType: "e",
          answerText: "Risposta e",
          recommendedProducts: [],
          notRecommendedProducts: [],
        },
      ],
    },

    {
      letterId: "b",
      question: "Lorem ipsum dolor sit amet?",
      answers: [
        {
          answerType: "a",
          answerText: "Risposta a",
          recommendedProducts: [],
          notRecommendedProducts: [],
        },
        {
          answerType: "b",
          answerText: "Risposta b",
          recommendedProducts: [],
          notRecommendedProducts: [],
        },
        {
          answerType: "c",
          answerText: "Risposta c",
          recommendedProducts: [],
          notRecommendedProducts: [],
        },
      ],
    },
    {
      letterId: "c",
      question: "Lorem ipsum dolor sit amet?",
      answers: [
        {
          answerType: "a",
          answerText: "Risposta a",
          recommendedProducts: [],
          notRecommendedProducts: [],
        },
        {
          answerType: "b",
          answerText: "Risposta b",
          recommendedProducts: [],
          notRecommendedProducts: [],
        },
      ],
    },
    {
      letterId: "d",
      question: "Lorem ipsum dolor sit amet?",
      answers: [
        {
          answerType: "a",
          answerText: "Risposta a",
          recommendedProducts: [],
          notRecommendedProducts: [],
        },
        {
          answerType: "b",
          answerText: "Risposta b",
          recommendedProducts: [],
          notRecommendedProducts: [],
        },
        {
          answerType: "c",
          answerText: "Risposta c",
          recommendedProducts: [],
          notRecommendedProducts: [],
        },
      ],
    },
    {
      letterId: "e",
      question: "Lorem ipsum dolor sit amet?",
      answers: [
        {
          answerType: "a",
          answerText: "Risposta a",
          recommendedProducts: [],
          notRecommendedProducts: [],
        },
        {
          answerType: "b",
          answerText: "Risposta b",
          recommendedProducts: [],
          notRecommendedProducts: [],
        },
        {
          answerType: "c",
          answerText: "Risposta c",
          recommendedProducts: [],
          notRecommendedProducts: [],
        },
      ],
    },
    {
      letterId: "c",
      question: "Lorem ipsum dolor sit amet?",
      answers: [
        {
          answerType: "a",
          answerText: "Risposta a",
          recommendedProducts: [],
          notRecommendedProducts: [],
        },
        {
          answerType: "b",
          answerText: "Risposta b",
          recommendedProducts: [],
          notRecommendedProducts: [],
        },
        {
          answerType: "c",
          answerText: "Risposta c",
          recommendedProducts: [],
          notRecommendedProducts: [],
        },
      ],
    },
  ]);

  return (
    <>
      <div className="container bg-color survey-table">
        <Fade>
          <div className="row">
            <div className="table-header py-3">
              <h3 className="list-title">Lista Surveys</h3>
            </div>
            <div className="table-navbar col-12">
              <div className="right-section col-12">
                <div className="search-add">
                  <SearchBar searchGetter={search} searchSetter={setSearch} />
                </div>
                {!search && (
                  <>
                    <div className="container-add mx-2">
                      <input
                        type="file"
                        id="uploadJSON"
                        style={{ display: "none" }}
                        onChange={handleUploadJSON}
                      />
                      <button
                        type="button"
                        className="main-btn add-icon"
                        onClick={() => document.getElementById("uploadJSON").click()}
                      >
                        <img src="/assets/svg/upload.svg" alt="" />
                      </button>
                    </div>
                    <div className="container-add mx-2">
                      <button
                        type="button"
                        className="main-btn add-icon"
                        onClick={() => handleEditSurvey(true)}
                      >
                        <img src="/assets/svg/add-circle.svg" alt="" />
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="table-container">
              {(filteredResults?.length > 0 && (
                <table className="table table-responsive table-borderless">
                  <thead>
                    <tr>
                      <th colSpan={1}>#</th>
                      <th colSpan={3}>Nome</th>
                      <th colSpan={4}>Descrizione</th>
                      <th colSpan={2}>Lingua</th>
                      <th colSpan={2}>Azioni</th>
                    </tr>
                  </thead>

                  <tbody>
                    {filteredResults?.map((survey, index) => (
                      <tr key={index}>
                        <td colSpan={1}>{index + 1}</td>
                        <td colSpan={3}>
                          {" "}
                          {(survey?.name?.length > 30 &&
                            `${survey?.name?.slice(0, 30)} ...`) ||
                            survey?.name}
                        </td>
                        <td colSpan={4}>
                          {(survey?.description?.length > 50 &&
                            `${survey?.description?.slice(0, 50)} ...`) ||
                            survey?.description}
                        </td>
                        <td colSpan={2}>
                          {survey?.language === "it" ? "Italiano" : "Inglese"}
                        </td>
                        <td colSpan={2}>
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="tooltip-edit">Modifica</Tooltip>}
                          >
                            <img
                              className="setting mx-0"
                              src="/assets/svg/edit.svg"
                              alt="Modifica"
                              style={{ width: "20px", height: "20px" }}
                              onClick={() => handleEditSurvey(survey)}
                            />
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="tooltip-edit">Duplica</Tooltip>}
                          >
                            <img
                              className="setting "
                              src="/assets/svg/duplicate.svg"
                              alt="Duplica"
                              onClick={() => handleDuplicate(survey)}
                            />
                          </OverlayTrigger>

                          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-edit">Impostazioni Q&A</Tooltip>}>
                            <img className="setting " src="/assets/svg/gear.svg" alt="Impostazioni Q&A" onClick={() => { handleSettingsSurvey(survey) }} />
                          </OverlayTrigger>

                          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-edit">Scarica un survey</Tooltip>}>
                            <img className="setting " src="/assets/svg/download.svg" alt="Impostazioni Q&A" onClick={() => { downloadJSON(survey) }} />
                          </OverlayTrigger>

                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )) || (
                  <div className="no-results">
                    <span>Nessun survey trovato.</span>
                  </div>
                )}
            </div>
          </div>
        </Fade>
      </div>

      <SurveyModal
        show={{ getter: showModal, setter: setShowModal }}
        data={currentSurvey}
      />

      <SettingsSurveyModal
        showModal={{
          getter: showModalSettingsSurveys,
          setter: setShowModalSettingsSurveys,
        }}
        currentSurvey={currentSurvey}
        setCurrentSurvey={setCurrentSurvey}
        surveyQuestionData={{
          getter: surveyQuestionData,
          setter: setSurveyQuestionData,
        }}
      />
    </>
  );
};

export default SurveyTable;
