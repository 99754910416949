import React, { useEffect } from 'react';
import { Bounce, Fade, Flip } from 'react-awesome-reveal';
import { Link } from 'react-router-dom';
import { useLanguage } from '../services/functions/LanguageProvider';

const Home = () => {
    const { language, changeLanguage, translations } = useLanguage();

    const handleLanguageChange = (selectedLanguage) => {
        changeLanguage(selectedLanguage);
    };


    useEffect(() => {
        const interval = setInterval(() => {
            window.location.reload();
        }, 60000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="home">
            <div className="video-background">
                <video src="/assets/videos/cottonbro.mp4" autoPlay muted playsInline />
            </div>

            <div className="languages">
                <div
                    className={`english ${language === 'en' ? 'active active-eng' : ''}`}
                    onClick={() => handleLanguageChange('en')}
                >
                    <img src="/assets/svg/flag-eng.svg" alt="" />
                </div>
                <div
                    className={`italian ${language === 'it' ? 'active active-ita' : ''}`}
                    onClick={() => handleLanguageChange('it')}
                >
                    <img src="/assets/svg/flag-it.svg" alt="" />
                </div>
            </div>

            <div className="content px-5">
                <Flip duration={1500}>
                    <div className="logo col text-center">
                        <img src="/assets/images/logo/vitamatch.png" alt="" />
                    </div>

                </Flip>
                <Fade delay={1600}>
                    <div className="subtitle text-center">
                        {translations[language]?.common?.subtitle}
                    </div>
                </Fade>
                <Bounce delay={2000} duration={1000} className="animation">
                    <Link to="/login" className="link">
                        <button type="button" className="main-btn">
                            <span>{translations[language]?.common?.start}</span>
                        </button>
                    </Link>
                </Bounce>
            </div>

            <div className="footer">
                <div className="warning col text-center px-4">
                    <span dangerouslySetInnerHTML={{ __html: translations[language]?.common?.footerHome }} />
                </div>
            </div>

        </div >
    );
};

export default Home;
