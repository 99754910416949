import axios from 'axios';
import CryptoJS from 'crypto-js';

const CLOUDINARY_NAME = 'dfgkgvz81';
const CLOUDINARY_API_KEY = '385132584948615';
const CLOUDINARY_API_SECRET = 'uT7IVlYj-ihQlS3wT3PnwP-oIdE';
const CLOUDINARY_PRESET = 'y7xrni1r';

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};


const convertToBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function(event) {
      resolve(event.target.result);
    };

    reader.onerror = function(error) {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
}

export const upload = async (data) => {

  const file = await convertToBase64(data);

  try {
    const uploadUrl = `https://api.cloudinary.com/v1_1/${CLOUDINARY_NAME}/image/upload`;

    const uploadData = {
      file: file,
      upload_preset: CLOUDINARY_PRESET,
      api_key: CLOUDINARY_API_KEY,
      api_secret: CLOUDINARY_API_SECRET,
    };

    const uploadResponse = await axios.post(uploadUrl, uploadData, config);
    const imageUrl = uploadResponse?.data?.secure_url;
    const newDeleteToken = uploadResponse?.data?.delete_token;

    return { imageUrl, token: newDeleteToken, public_id: uploadResponse?.data?.public_id };

  } catch (error) {
    console.error("Errore durante il caricamento dell'immagine su Cloudinary", error);
    throw new Error("Errore durante il caricamento dell'immagine su Cloudinary");
  }

}


export const remove = async (currentImage) => {
  const timestamp = Math.round(new Date().getTime() / 1000);
  
  const dataToHash = `public_id=${currentImage?.public_id}&timestamp=${timestamp}${CLOUDINARY_API_SECRET}`;
  const signature = CryptoJS.SHA1(dataToHash).toString(CryptoJS.enc.Hex);

  try {
    const uploadData = {
      public_id: currentImage?.public_id,
      api_key: CLOUDINARY_API_KEY,
      api_secret: CLOUDINARY_API_SECRET,
      token: currentImage?.token,
      timestamp,
      signature,
    };

    const response = await axios.post(`https://api.cloudinary.com/v1_1/${CLOUDINARY_NAME}/image/destroy`, uploadData, config);

    return response;
  } catch (error) {
    console.error("Errore durante l'eliminazione del token su Cloudinary", error);
  }
};

export const getImageByResolution = (url, res=50) => {
  
  if(url){
    const splitted = url?.split('upload/');
    const newUrl = splitted[0]+`upload/w_${res}/`+splitted[1];
    return newUrl;
  }

  return ''

}

