const SearchBar = ({ searchGetter, searchSetter }) => {
  return (
    <div className="searchbar">
      <button>
        <img src="/assets/svg/search.svg" alt="" />
      </button>
      <input
        type="text"
        placeholder="Cerca"
        value={searchGetter}
        onChange={(e) => searchSetter(e.target.value)}
      />

      {searchGetter && (
        <svg
          onClick={() => searchSetter("")}
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          fill="#00908e"
          className="bi bi-x clear-btn"
          viewBox="0 0 16 16"
        >
          <path
            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
            stroke="#00908e"
            strokeWidth=".8"
          />
        </svg>
      )}
    </div>
  );
};

export default SearchBar;
