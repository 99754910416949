import { useCallback, useEffect, useState } from "react";
import CreatableSelect from 'react-select/creatable';
import { Modal } from "react-bootstrap";
import addProduct from "../../services/collection/products/addProduct";
import deleteProduct from "../../services/collection/products/deleteProduct";
import editProduct from "../../services/collection/products/editProduct";
import ConfirmButton from "../buttons/ConfirmButton";
import {
  getImageByResolution,
  remove,
  upload,
} from "../../services/cloudinary";
import CloseModal from "./CloseModal";
import getSuggestions from "../../services/collection/surveys/getSuggestions";

const ProductModal = ({ show, data = {} }) => {
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);
  const [errorImg, setErrorImg] = useState(false);
  const [productImage, setProductImage] = useState();
  const [product, setProduct] = useState({ ...data });
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setProduct({ ...data });
  }, [data]);

  const handleSuggestions = useCallback(async () => {
    const response = await getSuggestions();
    const suggestions = response?.data?.map((item) => ({
      value: item,
      label: item,
    }));
    setSuggestions(suggestions || []);
  }, [data]);

  useEffect(() => {
    handleSuggestions();
  }, [handleSuggestions]);

  const handleConfirmClose = () => {
    if (loading === false) {
      show.setter(false);
      setProduct({});
      setShowCloseConfirmation(false);
    }
  };

  const handleClose = () => {
    if (loading === false) {
      setProduct({});
      show?.setter(false);
    }
  };

  const handleSubmit = async () => {
    if (!loading) {
      if (product?._id) {
        await editProduct({ ...product });
      } else {
        await addProduct({ ...product });
      }
      setProduct({});
      show?.setter(false);
    }
  };

  const handleDelete = async (product) => {
    if (loading === false) {
      if (product?.image) {
        await remove(product?.image);
      }
      await deleteProduct(product);
      setProduct({});
      show?.setter(false);
    }
  };

  const handleFile = async (e) => {
    e.preventDefault();
    setLoading(true);
    const file = e.target.files[0];

    if (file) {
      const allowedExtensions = ["jpg", "jpeg", "png", "gif", "svg"];
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (allowedExtensions.includes(fileExtension.toLowerCase())) {
        setErrorImg(false);

        if (product?.image) {
          await remove(product?.image);
        }
        const uploadedImage = await upload(file);
        await editProduct({ ...product, image: uploadedImage });
        show?.setter(false);
      } else {
        setErrorImg(true);
        setProductImage();
      }
    }
    setLoading(false);
  };

  const handleDeleteImage = async (product) => {
    if (product?.image) {
      await editProduct({ ...product, image: {} });
      await remove(product?.image);
      show?.setter(false);
    }
  };

  const handleDownload = async () => {
    try {
      if (product?.image?.imageUrl) {
        const response = await fetch(product.image.imageUrl);
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = `${product.name}.jpg`; 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error("Errore durante il download dell'immagine:", error);
    }
  };

  return (
    <>
      <Modal
        show={show?.getter}
        onHide={() => handleClose()}
        backdrop="static"
        keyboard={false}
        className="modal-add w-100"
      >
        <Modal.Header>
          <Modal.Title>
            <h4 className="m-0">
              {(data?._id && "Modifica il prodotto") ||
                "Aggiungi un nuovo prodotto"}
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="row  w-100">
              <div className="col-8">
                <label htmlFor="totem-name">Nome del prodotto * :</label>
                <br />
                <input
                  type="text"
                  id="totem-name"
                  name="totem-name"
                  value={product?.name || ""}
                  onChange={(e) =>
                    setProduct({ ...product, name: e.target.value })
                  }
                  className="input mb-2"
                  autoComplete="off"
                />
              </div>

              <div className="col-4 d-flex justify-content-center align-items-center">
                <div className="">
                  <div className="upload-container d-flex justify-content-center">
                    <input
                      className="d-none"
                      id="file-input"
                      type="file"
                      onChange={handleFile}
                      name="image"
                      accept=".jpg, .jpeg, .png, .gif, .svg"
                    />

                    {loading ? (
                      <div className="spinner-border" role="status"></div>
                    ) : (
                      <>
                        {product?.image?.imageUrl && (
                          <span
                            className="deleteImg"
                            onClick={() => handleDeleteImage(product)}
                          >
                            <img src="/assets/svg/delete.svg" alt="" />
                          </span>
                        )}

                        <label htmlFor="file-input" className="upload-label">
                          {product?.image?.imageUrl ? (
                            <img
                              src={getImageByResolution(
                                product?.image?.imageUrl,
                                200
                              )}
                              alt="..."
                              className="product-image img-thumbnail"
                            />
                          ) : (
                            <img
                              src={"/assets/images/defaultImage.jpg"}
                              alt="..."
                              className="product-image img-thumbnail"
                            ></img>
                          )}
                        </label>

                        {product?.image?.imageUrl && (
                          <span
                            className="download-icon"
                            onClick={handleDownload}
                          >
                            <img
                              src="/assets/svg/download.svg"
                              alt="Download"
                            />
                          </span>
                        )}
                      </>
                    )}
                  </div>

                  {errorImg && (
                    <label className="label-format">
                      Formato non valido! Si accettano solo file jpg, jpeg o
                      png.
                    </label>
                  )}
                </div>
              </div>
            </div>
            <br />

            <label htmlFor="totem-desc">Descrizione:</label>
            <br />
            <textarea
              id="totem-desc"
              name="totem-desc"
              value={product?.description || ""}
              onChange={(e) =>
                setProduct(() => {
                  return { ...product, description: e.target.value };
                })
              }
              rows="4"
              cols="50"
              className="textarea"
            ></textarea>

            <div className="row w-100 mb-5">
              <div className="col-6 mt-3">
                <label htmlFor="recommendedFor">Raccomandato per:</label>
                <br />
                <CreatableSelect
                  value={product?.recommendedFor?.map((item) => ({
                    value: item,
                    label: item,
                  }))}
                  isMulti
                  name="recommendedFor"
                  options={suggestions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Seleziona o aggiungi opzioni..."
                  // noOptionsMessage={() => 'Nessuna opzione disponibile'}
                  formatCreateLabel={(inputValue) => `Aggiungi "${inputValue}"`}
                  onChange={(selected) => {
                    const recommendedForList = selected?.map(
                      (item) => item.value
                    );
                    setProduct({
                      ...product,
                      recommendedFor: recommendedForList,
                    });
                  }}
                />
              </div>
              <div className="col-6 mt-3">
                <label htmlFor="notRecommendedFor">Sconsigliato per:</label>
                <br />
                <CreatableSelect
                  value={product?.notRecommendedFor?.map((item) => ({
                    value: item,
                    label: item,
                  }))}
                  isMulti
                  name="notRecommendedFor"
                  options={suggestions}
                  className="basic-multi-select red-basic-multi-select "
                  classNamePrefix="select"
                  placeholder="Seleziona o aggiungi opzioni..."
                  // noOptionsMessage={() => 'Nessuna opzione disponibile'}
                  formatCreateLabel={(inputValue) => `Aggiungi "${inputValue}"`}
                  onChange={(selected) => {
                    const notRecommendedForList = selected?.map(
                      (item) => item.value
                    );
                    setProduct({
                      ...product,
                      notRecommendedFor: notRecommendedForList,
                    });
                  }}
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer
          className={`${data?._id ? "d-flex justify-content-between" : ""}`}
        >
          {(data?._id && (
            <div className="left-btns">
              <ConfirmButton
                className="me-5"
                label="Elimina Prodotto"
                danger={true}
                action={handleDelete}
                data={data}
              />
            </div>
          )) || <></>}

          <div className="right-btn ">
            <button
              className="cancel-btn mx-3"
              onClick={() => setShowCloseConfirmation(true)}
            >
              Annulla
            </button>
            <button
              type="button"
              className="main-btn"
              onClick={handleSubmit}
              disabled={!product?.name}
            >
              {(data?._id && "Aggiorna") || "Aggiungi"}
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Alert di conferma chiusura modale senza salvare possibili modifiche */}
      {showCloseConfirmation && (
        <CloseModal
          show={showCloseConfirmation}
          onConfirmClose={handleConfirmClose}
          onDontClose={() => setShowCloseConfirmation(false)}
        />
      )}
    </>
  );
};

export default ProductModal;
