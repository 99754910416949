import { useState, useEffect } from "react";
import { BarChart, PieChart } from "@mui/x-charts";
import { Fade } from "react-awesome-reveal";

import retrieveTotems from "../services/collection/totem/retrieveTotems";
import retrieveSurveys from "../services/collection/surveys/retrieveSurveys";
import retrieveProducts from "../services/collection/products/retrieveProducts";
import retrieveResults from "../services/collection/results/retrieveResults";

// Funzione per ottenere colori personalizzati
const getColorForIndex = (index) => {
  // Creo una lista di colori pastel per la visualizzazione dei grafici
  const pastelColors = [
    '#f8c3a3', // Pastel Peach
    '#f4d46e', // Pastel Sunflower 
    '#b3e5c7', // Pastel Mint Green 
    '#89c3eb', // Pastel Sky Blue 
    '#e0bbe4', // Pastel Lilac 
    '#f6a6a6', // Pastel Coral
    '#d6bbf8', // Pastel Lavender 
    '#9ad1a7', // Pastel Mint
    '#f8e4a1', // Pastel Cream
    '#9ae4e2', // Pastel Aqua 
    '#f5a7a7', // Pastel Salmon 
    '#a6c2f2', // Pastel Periwinkle 
    '#c4e8f2', // Pastel Light Blue 
    '#d8e2b8', // Pastel Light Olive 
    '#f6c2c2', // Pastel Coral 
    '#c49beb', // Pastel Orchid 
    '#b1e2a7', // Pastel Lime
    '#f7c1d9', // Pastel Rose 
    '#b2e5c8', // Pastel Celadon 
    '#e8b3b3', // Pastel Pink
    '#c9e8a5'  // Pastel Sage 
  ];
  return pastelColors[index % pastelColors.length];
};

// Calcolo i dati per le diverse sezioni
const calculateChartData = (data, type) => {
  if (!data || !Array.isArray(data)) return [];

  if (type === 'survey') {
    // Calcolo il numero di sondaggi per categoria
    const categoryCounts = data.reduce((acc, survey) => {
      survey.questions.forEach(q => {
        const category = q.questionCategory.label
        // Escludo le categorie "tutti"/"all"
        if (category !== 'Tutti' && category !== 'All') {
          acc[category] = (acc[category] || 0) + 1;
        }
      });
      return acc;
    }, {});
  
    // Converto le categorie in un array con colore
    return Object.entries(categoryCounts).map(([category, count], index) => ({
      id: index,
      value: count || 0,
      label: category,
      color: getColorForIndex(index)
    }));
  }

  if (type === 'product') {
    // Calcolo la percentuale di prodotti con raccomandazioni e controindicazioni
    const totalProducts = data.length;
    const productsWithRecommendations = data.filter(p => p.recommendedFor.length > 0).length;
    const productsWithNonRecommendations = data.filter(p => p.notRecommendedFor.length > 0).length;
    return [
      { id: 0, value: (productsWithRecommendations / totalProducts) * 100 || 0, label: 'Percentuale con Raccomandazioni', color: '#a2c2e4' },
      { id: 1, value: (productsWithNonRecommendations / totalProducts) * 100 || 0, label: 'Percentuale con Controindicazioni', color: '#a2c2e4' }
    ];
  }

  if (type === 'result') {
    // Calcolo il numero di risultati per totem
    const resultCounts = data.reduce((acc, result) => {
      const formattedTotemName = result.totemName.charAt(0).toUpperCase() + result.totemName.slice(1).toLowerCase();
      acc[formattedTotemName] = (acc[formattedTotemName] || 0) + 1;
      return acc;
    }, {});

    return Object.entries(resultCounts).map(([totemName, count], index) => ({
      id: index,
      value: count || 0,
      label: totemName,
      color: getColorForIndex(index)
    }));
  }

  // Default: Totems
  // Calcolo il numero di totem attivi, scaduti e la suddivisione per lingua dei sondaggi associati
  let expiredTotems = 0;
  let activeTotems = 0;
  let englishSurveys = 0;
  let italianSurveys = 0;

  data.forEach(item => {
    const expirationDate = new Date(item.contractExpirationDate);
    if (expirationDate < new Date()) expiredTotems++;
    else activeTotems++;
    if (item.surveyEN) englishSurveys++;
    if (item.surveyIT) italianSurveys++;
  });

  return [
    { id: 1, value: activeTotems || 0, label: 'Totems Attivi', color: getColorForIndex(0) },
    { id: 2, value: expiredTotems || 0, label: 'Totems Scaduti', color: getColorForIndex(1) },
    { id: 3, value: englishSurveys || 0, label: 'Surveys in Inglese', color: getColorForIndex(2) },
    { id: 4, value: italianSurveys || 0, label: 'Surveys in Italiano', color: getColorForIndex(3) }
  ];
};

const calculateDatasetForBarChart = (data) => {
  // Estrazione dei nomi dei totem unici
  const totemNames = Array.from(new Set(data.map(result => result.totemName)));
  const resultsByMonth = {};

  // Controllo di avere tutti i mesi da gennaio a dicembre
  for (let i = 0; i < 12; i++) {
    const month = new Date(0, i).toLocaleString('default', { month: 'short' });
    resultsByMonth[month] = totemNames.reduce((acc, name) => ({ ...acc, [name]: 0 }), {});
  }

  // Popolazione dei risultati
  data.forEach(result => {
    const month = new Date(result.surveyDate).toLocaleString('default', { month: 'short' });
    if (resultsByMonth[month]) {
      resultsByMonth[month][result.totemName]++;
    }
  });

  // Conversione dei risultati in un array per il dataset
  return Object.entries(resultsByMonth).map(([month, totems]) => ({ month, ...totems }));
};

// Componente CategoryChart
const CategoryChart = ({ title, data, type, description }) => {
  // Calcolo i dati del grafico in base al tipo
  const chartData = calculateChartData(data, type);
  const dataset = type === 'result' ? calculateDatasetForBarChart(data) : [];
  const totemNames = Array.from(new Set(data.map(result => result.totemName)));

  // Funzione per suddividere i dati della legenda in colonne
  const getLegendColumns = (data, itemsPerColumn) => {
    const columns = [];
    for (let i = 0; i < data.length; i += itemsPerColumn) {
      columns.push(data.slice(i, i + itemsPerColumn));
    }
    return columns;
  };

  const legendColumns = getLegendColumns(chartData, 8);

  return (
    <div className="col-12 mb-4">
      <div className="chart-container" style={{ width: "max-content" }}>
        <h6 className="list-title" style={{ fontSize: '20px', color: 'black' }}>{title}</h6>
        {description && <p className="chart-description mt-3">{description}</p>}

        <div className="chart" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ flex: 1 }}>
            {type === 'product' ? (
              <BarChart
                xAxis={[{ scaleType: 'band', data: chartData.map(item => item.label) }]}
                series={[{ data: chartData.map(item => item.value), color: chartData.map(item => item.color) }]}
                height={300}
                width={1000}
              />
            ) : (
              <PieChart
                series={[{
                  data: chartData,
                  highlightScope: { faded: 'global', highlighted: 'item' },
                  faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                  color: chartData.map(item => item.color)
                }]}
                height={300}
                width={500}
              />
            )}
          </div>
          {type !== 'product' && (
            <div className="custom-legend" style={{ flex: 1, margin: '15px', display: 'grid', gridTemplateColumns: `repeat(${legendColumns.length}, 1fr)`, gap: '10px' }}>
              {legendColumns.map((column, columnIndex) => (
                <div key={columnIndex} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}>
                  {column.map(item => (
                    <div key={item.id} style={{ display: 'flex', margin: '5px' }}>
                      <div
                        style={{
                          width: '20px',
                          height: '20px',
                          backgroundColor: item.color,
                          marginRight: '10px',
                          border:'1px solid white'
                        }}
                      />
                      <span>{item.label}</span>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
        </div>
        {type === 'result' && (
          <div className="chart" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <BarChart
              dataset={dataset}
              xAxis={[{ scaleType: 'band', dataKey: 'month' }]}
              series={totemNames.map((name, index) => ({
                dataKey: name,
                label: name,
                color: getColorForIndex(index)
              }))}
              height={300}
              width={1000}
            />
          </div>
        )}
      </div>
    </div>
  );
};

// Componente principale ReportsTable
const ReportsTable = () => {
  const [totems, setTotems] = useState([]);
  const [surveys, setSurveys] = useState([]);
  const [products, setProducts] = useState([]);
  const [results, setResults] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [totemsData, surveysData, productsData, resultsData] = await Promise.all([
          retrieveTotems(),
          retrieveSurveys(),
          retrieveProducts(),
          retrieveResults()
        ]);

        setTotems(totemsData?.data || []);
        setSurveys(surveysData?.data || []);
        setProducts(productsData?.data || []);
        setResults(resultsData?.data || []);

      } catch (error) {
        console.error("Errore nel recupero dei dati: ", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="container d-flex flex-column justify-content-start bg-color reports-table">
      <Fade>
        <div className="table-header py-3">
          <h3 className="list-title">Reports</h3>
        </div>

        <div className="scrollable-section">
          {[
            { title: "Panoramica dei Totem: Stato e Lingua dei Sondaggi", data: totems, description: "Questo grafico mostra il numero di totem attivi, scaduti e la suddivisione per lingua dei sondaggi associati." },
            { title: "Panoramica dei Sondaggi: Distribuzione per Categoria", data: surveys, type: "survey", description: "Questo grafico rappresenta la distribuzione dei surveys per categoria." },
            { title: "Panoramica dei Prodotti: Percentuale di Raccomandazioni e Controindicazioni", data: products, type: "product", description: "Il grafico mostra la percentuale di prodotti con raccomandazioni e controindicazioni." },
            { title: "Risultati per Totem: Distribuzione e Andamento Mensile", data: results, type: "result", description: "Il grafico a torta mostra il numero di risultati per totem, mentre il grafico a barre sottostante visualizza l'andamento mensile dei risultati." }
          ].map((chart, index) => (
            <div className="section mt-4" key={index} >
              <div className="row">
                <CategoryChart {...chart} />
              </div>
            </div>
          ))}
        </div>
      </Fade>
    </div>
  );
};

export default ReportsTable;
