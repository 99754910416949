import { useState, useEffect } from "react";

import { Fade } from "react-awesome-reveal";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import SearchBar from "./SearchBar";
import CloseModal from "./modals/CloseModal";
import retrieveTotems from "../services/collection/totem/retrieveTotems";
import retrieveResults from "../services/collection/results/retrieveResults";
import dateFormater from "../services/functions/dateFormater";
import downloadCSV from "../services/collection/downloadCSV";

const ResultsTable = () => {
  const [showModalAddResult, setShowModalAddResult] = useState(false);
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);
  // eslint-disable-next-line
  const [showModalEditResult, setShowModalEditResult] = useState(false);
  const [resultName, setResultName] = useState("");
  const [resultDesc, setResultDesc] = useState("");

  const [results, setResults] = useState([]);

  const [filteredResults, setFilteredResults] = useState(results);
  const [search, setSearch] = useState("");
  const [filterByDays, setFilterByDays] = useState(0);
  const [filterByTotem, setFilterByTotem] = useState("");

  const [totemsList, setTotemsList] = useState([]);

  const handleResults = async () => {
    const response = await retrieveResults();
    setResults(() => response?.data);
    setFilteredResults(() => response?.data);

    // console.log("RESULTS ---> ", response?.data);
  };

  const handleTotemsList = async () => {
    const response = await retrieveTotems();
    setTotemsList(() => response?.data);
  };

  console.log("RESULTS INFO -->", results)

  useEffect(() => {
    handleResults();
  }, []);

  useEffect(() => {
    const now = new Date();
    now.setHours(0, 0, 0, 0);

    const filteredItems = results
      ?.filter(
        (result) =>
          result?.totemName?.toLowerCase()?.includes(search?.toLowerCase()) ||
          result?.surveyName?.toLowerCase()?.includes(search?.toLowerCase()) ||
          result?.surveyDate?.toLowerCase()?.includes(search?.toLowerCase()) ||
          result?.surveyDescription?.toLowerCase()?.includes(search?.toLowerCase()) ||
          result?.surveyCategories?.toLowerCase()?.includes(search?.toLowerCase())
      )
      ?.filter((result) => {
        if (filterByTotem?.length) {
          return result?.totemName?.toLowerCase()?.includes(filterByTotem?.toLowerCase());
        } else {
          return result;
        }
      })
      ?.filter((result) => {
        if (filterByDays === 0) {
          return true;
        }

        const surveyDate = new Date(result?.surveyDate);
        surveyDate.setHours(0, 0, 0, 0);

        const dateLimitStart = new Date();
        dateLimitStart.setDate(now.getDate() - filterByDays);
        dateLimitStart.setHours(0, 0, 0, 0);

        const dateLimitEnd = new Date(dateLimitStart);
        dateLimitEnd.setDate(dateLimitStart.getDate() + 1);

        return surveyDate >= dateLimitStart && surveyDate < dateLimitEnd;
      });

    setFilteredResults(filteredItems);
  }, [search, filterByDays, filterByTotem, results]);

  const handleClose = () => {
    if (showCloseConfirmation) {
      setShowModalAddResult(false);
    } else {
      setShowCloseConfirmation(true);
    }
  };


  // const handleClose = () => {
  //   if (showCloseConfirmation) {
  //     setShowModalAddResult(false);
  //   } else {
  //     setShowCloseConfirmation(true);
  //   }
  // };

  // const handleConfirmClose = () => {
  //   setShowModalAddResult(false);
  //   setShowCloseConfirmation(false);
  // };

  // const handleCancelClose = () => {
  //   setShowCloseConfirmation(false);
  // };

  // const handleSubmit = (event) => {
  //   event.preventDefault();

  //   const newResult = {
  //     id: results?.length,
  //     name: resultName,
  //     description: resultDesc,
  //   };

  //   setResults([...results, newResult]);

  //   setResultName("");
  //   setResultDesc("");

  //   setShowModalAddResult(false);
  // };

  useEffect(() => {
    handleTotemsList();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="container bg-color results-table">
        <Fade>
          <div className="row">
            <div className="table-header col-12">
              <h3 className="list-title col-5">Lista Risultati</h3>
              <div className="btns col-7 text-end">
                <button
                  type="button"
                  className="main-btn mx-3 btn-results"
                  onClick={() => downloadCSV()}
                >
                  Scarica CSV
                </button>
                <button type="button" className="main-btn btn-results">
                  Sincronizza
                </button>
              </div>
            </div>
            <div className="table-navbar col-12">
              <div className="left-section d-flex col-6 justify-content-between">
                <Form.Select
                  aria-label="Seleziona un totem"
                  defaultValue=""
                  onChange={(e) => setFilterByTotem(e.target.value)}
                >
                  <option value="">Tutti i Totem</option>
                  {totemsList.map((totem) => (
                    <option key={totem?._id} value={totem?.email}>
                      {totem.email}
                    </option>
                  ))}
                </Form.Select>

                <Form.Select
                  aria-label="Seleziona una data"
                  className="ms-3"
                  onChange={(e) => setFilterByDays(Number(e.target.value))}
                >
                  <option value={0}>Tutte</option>
                  <option value={1}>Un giorno fa</option>
                  <option value={7}>Una settimana fa</option>
                  <option value={30}>Un mese fa</option>
                  <option value={365}>Un anno fa</option>
                </Form.Select>
              </div>

              <div className="right-section col-6">
                <div className="search-add ">
                  <SearchBar searchGetter={search} searchSetter={setSearch} />
                </div>
              </div>
            </div>

            <div className="table-container">
              {(filteredResults?.length > 0 && (
                <table className="table table-responsive table-borderless">
                  <thead>
                    <tr>
                      <th colSpan={1}>#</th>
                      <th colSpan={3}>Totem</th>
                      <th colSpan={3}>Survey</th>
                      <th colSpan={2}>Lingua</th>
                      <th colSpan={4}>Categorie</th>
                      <th colSpan={2}>Data</th>
                      {/* <th colSpan={2}>Azione</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {filteredResults?.map((result, index) => (
                      <tr key={result?.id}>
                        <td colSpan={1}>{index + 1}</td>
                        <td colSpan={3}>
                          {(result?.totemName?.length > 10 &&
                            `${result?.totemName?.slice(0, 10)} ...`) ||
                            result?.totemName}
                        </td>
                        <td colSpan={3}>
                          {(result?.surveyName?.length > 30 &&
                            `${result?.surveyName?.slice(0, 30)} ...`) ||
                            result?.surveyName}
                        </td>
                        <td colSpan={2}>{result?.surveyLanguage}</td>
                        <td colSpan={4}>{result?.surveyCategories}</td>
                        <td colSpan={2}>{dateFormater(result?.surveyDate)}</td>

                        {/* <td
                          colSpan={2}
                          onClick={() => setShowModalEditResult(true)}
                        >
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="tooltip-edit">Modifica</Tooltip>
                            }
                          >
                            <img
                              className="setting"
                              src="/assets/svg/edit.svg"
                              alt=""
                              style={{ width: "20px", height: "20px" }}
                            />
                          </OverlayTrigger>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              )) || (
                <div className="no-results">
                  <span>Nessun risultato trovato.</span>
                </div>
              )}
            </div>
          </div>
        </Fade>
      </div>

      {/* <Modal
        show={showModalAddResult}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="modal-add"
      >
        <Modal.Header>
          <Modal.Title>
            <h4 className="m-0">Aggiungi un nuovo Risultato</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <label htmlFor="result-name">Nome del Risultato :</label>
            <br />
            <input
              type="text"
              id="result-name"
              name="result-name"
              value={resultName}
              onChange={(e) => setResultName(e.target.value)}
              className="input mb-2"
            />
            <br />

            <label htmlFor="result-desc">Descrizione :</label>
            <br />
            <textarea
              id="result-desc"
              name="result-desc"
              value={resultDesc}
              onChange={(e) => setResultDesc(e.target.value)}
              rows="4"
              cols="50"
              className="textarea"
            ></textarea>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button className="cancel-btn" onClick={handleClose}>
            Annulla
          </button>
          <button
            type="button"
            className="main-btn"
            onClick={handleSubmit}
            disabled={!resultName}
          >
            Aggiungi
          </button>
        </Modal.Footer>
      </Modal> */}

      {/* Alert di conferma chiusura modale senza salvare possibili modifiche */}
      {/* {showCloseConfirmation && (
        <CloseModal
          show={showCloseConfirmation}
          onConfirmClose={handleConfirmClose}
          onDontClose={handleCancelClose}
        />
      )} */}
    </>
  );
};

export default ResultsTable;
