import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { useNavigate } from "react-router";
import { useLanguage } from "../services/functions/LanguageProvider";
import askToSync from "../services/collection/askToSync";

const Survey = () => {
  const navigate = useNavigate();
  const { translations, language } = useLanguage();
  const commonTranslations = translations[language].common;
  const categoryTranslations = translations[language].categories;

  const [survey, setSurvey] = useState(null);
  const [result, setResult] = useState(null);

  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [surveyResponses, setSurveyResponses] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const [slideDirection, setSlideDirection] = useState("slide-left");

  const needs = {
    questionText: commonTranslations.needsQuestion,
    possibleAnswers: [
      {
        answerIcon: "/assets/surveyIcons/brain.png",
        answerText: categoryTranslations.mind,
        answerCategory: "mind",
      },
      {
        answerIcon: "/assets/surveyIcons/bone.png",
        answerText: categoryTranslations.bones,
        answerCategory: "bones",
      },
      {
        answerIcon: "/assets/surveyIcons/digestion.png",
        answerText: categoryTranslations.digestion,
        answerCategory: "digestion",
      },
      {
        answerIcon: "/assets/surveyIcons/energy.png",
        answerText: categoryTranslations.energy,
        answerCategory: "energy",
      },
      {
        answerIcon: "/assets/surveyIcons/hair.png",
        answerText: categoryTranslations.hair,
        answerCategory: "hair",
      },
      {
        answerIcon: "/assets/surveyIcons/heart.png",
        answerText: categoryTranslations.heart,
        answerCategory: "heart",
      },
      {
        answerIcon: "/assets/surveyIcons/shield.png",
        answerText: categoryTranslations.defenses,
        answerCategory: "defenses",
      },
      {
        answerIcon: "/assets/surveyIcons/skin.png",
        answerText: categoryTranslations.skin,
        answerCategory: "skin",
      },
      {
        answerIcon: "/assets/surveyIcons/sleep.png",
        answerText: categoryTranslations.sleep,
        answerCategory: "sleep",
      },
      {
        answerIcon: "/assets/surveyIcons/stress.png",
        answerText: categoryTranslations.stress,
        answerCategory: "stress",
      },
      {
        answerIcon: "/assets/surveyIcons/weight.png",
        answerText: categoryTranslations.weight,
        answerCategory: "weight",
      },
      {
        answerIcon: "/assets/surveyIcons/woman.png",
        answerText: categoryTranslations.womenHealth,
        answerCategory: "womenHealth",
      },
      {
        answerIcon: "/assets/surveyIcons/man.png",
        answerText: categoryTranslations.menHealth,
        answerCategory: "menHealth",
      },
    ],
  };

  useEffect(() => {
    const surveyJSON = localStorage.getItem("TotemVitaMatchSURVEY");
    const surveys = surveyJSON ? JSON.parse(surveyJSON) : "";
    const survey = surveys[language] || {};

    if (
      !survey ||
      !localStorage.getItem("TotemVitaMatchTOKEN") ||
      !localStorage.getItem("TotemVitaMatchSURVEY")
    ) {
      navigate("/");
    }

    const questions = [needs];

    console.log("SURVEY ---> ",survey)

    setSurvey(() => survey);
    setQuestions(() => questions);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const categories = selectedAnswers?.map((index) => {
      return needs?.possibleAnswers[index]?.answerCategory;
    });

    setSelectedCategories(() => categories);

    const current = {
      ...result,
      surveyCategories: categories?.join(" - "),
    };

    setResult(() => current);
  }, [selectedAnswers]);

  const handleResponse = (answerIndex, question, answer) => {
    saveResponse(question, answer);

    if (currentQuestion === 0) {
      handleFirstQuestionResponse(answerIndex);
    } else {
      goToNextQuestion(question, answer);
      setSurveyResponses((prevResponses) => {
        const updatedResponses = [...prevResponses];
        updatedResponses[currentQuestion] = answerIndex;
        return updatedResponses;
      });
    }
  };

  const saveResponse = (question, answer) => {
    const currentResult = {
      question: question?.questionText,
      answer: answer?.answerText,
    };

    const recommendedProducts = answer?.recommendedProducts || [];
    const notRecommendedProducts = answer?.notRecommendedProducts || [];

    const clenedResults =
      result?.results?.filter(
        (result) =>
          result?.question !== currentResult?.question &&
          result?.question !== "What are your necessities?"
      ) || [];

    let cleanedProducts = result?.suggestedProducts || [];

    for (let product of recommendedProducts) {
      if (!cleanedProducts?.includes(product)) {
        cleanedProducts?.push(product);
      }
    }

    for (let product of notRecommendedProducts) {
      if (!cleanedProducts?.includes(product)) {
        cleanedProducts = cleanedProducts?.filter((item) => item !== product);
      }
    }

    if (!result?.surveyDate) {
      const code =
        survey?.language +
        "" +
        survey?.name?.slice(0, 3) +
        "" +
        Math.floor(Date.now() / 1000) +
        "" +
        survey?.name?.slice(-3);

      const current = {
        totemName:'',
        surveyName: survey?.name,
        surveyDescription: survey?.description,
        surveyLanguage: survey?.language,
        resultCode: code,
        surveyDate: Date?.now(),
        results: [...clenedResults, { ...currentResult }],
        suggestedProducts: [...cleanedProducts],
      };

      setResult(() => current);
    } else {
      const current = {
        ...result,
        results: [...clenedResults, { ...currentResult }],
        suggestedProducts: [...cleanedProducts],
      };
      setResult(() => current);
    }
  };

  const handleSync = async (results) => {
    if (results && results.length > 0) {
      try {
        await Promise.all(results.map((result) => askToSync(result)));
        localStorage.removeItem("TotemVitaMatchRESULTS");
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleFirstQuestionResponse = (answerIndex) => {
    if (selectedAnswers.includes(answerIndex)) {
      setSelectedAnswers((prevAnswers) =>
        prevAnswers.filter((index) => index !== answerIndex)
      );
    } else if (selectedAnswers.length < 6) {
      setSelectedAnswers((prevAnswers) => [...prevAnswers, answerIndex]);
    }
  };

  const goToNextQuestion = (currentQuestion, currentAnswer) => {
    const originalQuestionList = survey?.questions || [];

    const surveyQuestions = originalQuestionList?.filter((question) => {
      if (
        selectedCategories?.includes(question?.questionCategory?.value) ||
        question?.questionCategory?.value === "all"
      ) {
        return question;
      }
    });

    let currentQuestions = questions || [];

    const index = surveyQuestions.findIndex(
      (q) => q._id === currentQuestion._id
    );

    let currentQuestionIndex = index === -1 ? 0 : index;

    if (currentQuestionIndex === 0) {
      const updatedQuestions = [...currentQuestions, surveyQuestions[0]];
      setQuestions(() => updatedQuestions);
    }

    const leftQuestions = [...surveyQuestions]?.slice(currentQuestionIndex);
    const nextQuestion =
      leftQuestions?.find(
        (question) =>
          question?.questionType === currentAnswer?.answerType &&
          question?._id !== currentQuestion?._id
      ) ||
      leftQuestions?.find(
        (question) =>
          question?.questionType !== currentAnswer?.answerType &&
          question?._id !== currentQuestion?._id
      ) ||
      leftQuestions[currentQuestionIndex] ||
      null;
    const updatedQuestions =
      nextQuestion &&
      !questions?.map((item) => item._id)?.includes(nextQuestion?._id)
        ? [...questions, nextQuestion]
        : questions;

    setQuestions(() => updatedQuestions);
    setCurrentQuestion((prevQuestion) =>
      nextQuestion ? prevQuestion + 1 : prevQuestion + 2
    );
    setSlideDirection("slide-left");
  };

  const redirectToCongratulations = () => {

    const randomTimeout = Math.floor(Math.random() * (600 - 300 + 1) + 300);

    const internalFunction = () => {
      const storedResults =
        localStorage.getItem("TotemVitaMatchRESULTS") || null;

      const oldResults = storedResults ? JSON.parse(storedResults) : [];
      const filteredResults = oldResults?.filter(
        (item) => item?.surveyDate !== result?.surveyDate
      );
      const newResults = [...filteredResults, result];

      localStorage.setItem("TotemVitaMatchRESULTS", JSON.stringify(newResults));
      localStorage.setItem("TotemVitaMatchCURRENT", JSON.stringify(result));

      if (newResults && newResults?.length) {
        handleSync(newResults);
      }

      navigate("/end-of-survey");
    };

    setTimeout(internalFunction, randomTimeout);

  };

  const handleBackQuestion = (question) => {
    let currentQuestions = questions || [];
    const currentQuestionIndex = currentQuestions?.findIndex(
      (q) => q._id === question._id
    );
    const leftQuestions = [...currentQuestions].slice(0, currentQuestionIndex);

    setQuestions(() => leftQuestions);
    setCurrentQuestion((prevQuestion) => prevQuestion - 1);
    setSlideDirection("slide-right");
  };

  return (
    <div className="survey flex-column">
      <Header />

      <button type="button" className=" back-home main-btn">
        <a href="/">{translations[language]?.common?.backToHome}</a>
      </button>

      {questions?.length === 0 ? (
        <>
          <div className="container ">
            <div className="row">
              <div className="questions d-flex justify-content-center align-items-center">
                <div className="question-slide ">
                  <h4>{translations[language]?.common?.noSurvey}...</h4>
                  <div className="first-question-btn-group d-flex justify-content-center">
                    <button type="button" className="main-btn mt-5 ">
                      <a href="/">Home</a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="container ">
            <div className="row">
              <div
                className={`text-center question ${
                  currentQuestion === 0 ? "first-question" : ""
                }`}
              >
                {currentQuestion < questions.length ? (
                  <div className="questions">
                    <div
                      key={currentQuestion}
                      className={`question-slide ${slideDirection}`}
                    >
                      <h4>{questions[currentQuestion].questionText}</h4>

                      <div
                        className={`btn-group ${
                          currentQuestion === 0
                            ? "first-question-btn-group"
                            : ""
                        }`}
                      >
                        {questions[currentQuestion].possibleAnswers.map(
                          (answer, answerIndex) => (
                            <div key={answerIndex}>
                              {currentQuestion === 0 ? (
                                <button
                                  className={`first-question-btn ${
                                    selectedAnswers.includes(answerIndex)
                                      ? "active"
                                      : ""
                                  } 
                                  ${
                                    selectedAnswers.length === 6 &&
                                    !selectedAnswers.includes(answerIndex)
                                      ? "disabled"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    handleResponse(
                                      answerIndex,
                                      questions[currentQuestion],
                                      answer
                                    )
                                  }
                                  disabled={
                                    selectedAnswers.length === 6 &&
                                    !selectedAnswers.includes(answerIndex)
                                  }
                                >
                                  <img
                                    className="answer-icon mb-2"
                                    src={answer.answerIcon}
                                    alt={answer.answerText}
                                  />
                                  {answer.answerText}
                                </button>
                              ) : (
                                <button
                                  key={answerIndex}
                                  className={`answers-btn ${
                                    surveyResponses[currentQuestion] ===
                                    answerIndex
                                      ? "active"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    handleResponse(
                                      answerIndex,
                                      questions[currentQuestion],
                                      answer
                                    )
                                  }
                                >
                                  {answer.answerText}
                                </button>
                              )}
                            </div>
                          )
                        )}
                      </div>

                      {currentQuestion === 0 && selectedAnswers.length > 0 && (
                        <button
                          type="button"
                          className="main-btn mt-5"
                          onClick={() =>
                            goToNextQuestion(
                              questions[currentQuestion],
                              "answer"
                            )
                          }
                        >
                          {translations[language]?.common?.confirm}
                        </button>
                      )}
                      {currentQuestion === 0 && selectedAnswers.length === 0 && (
                        <button
                          type="button"
                          className="main-btn mt-5 btn-disabled "
                          disabled
                        >
                          {translations[language]?.common?.confirm}
                        </button>
                      )}
                    </div>

                    {currentQuestion >= 1 && (
                      <button
                        type="button"
                        className="mt-5 back-question main-btn"
                        onClick={() =>
                          handleBackQuestion(
                            questions[currentQuestion],
                            "answer"
                          )
                        }
                      >
                        <img src="/assets/svg/back.png" alt="" />
                        {translations[language]?.common?.previousQuestion}
                      </button>
                    )}
                  </div>
                ) : (
                  redirectToCongratulations()
                )}
              </div>
            </div>
          </div>
          <div className="footer survey-footer">
            <div className="warning col text-center px-4">
              <span
                dangerouslySetInnerHTML={{
                  __html: translations[language]?.common?.footerSurvey,
                }}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Survey;
