import { Link } from "react-router-dom";

const Page404 = () => {
    return (
        <>
            <div className="pagina404 main-center">
                <img src="/assets/images/404.gif" alt="" />
                <Link to="/" className="link mt-3">
                    <button type="button" className="main-btn">
                        <span>Torna indietro</span>
                    </button>
                </Link>
            </div>
        </>
    );
};

export default Page404;
