import request from "../../request";

const retrieveProducts = async () => {
  const options = {
    url: `/products`,
    method: "GET",
  };

  return await request({ ...options });
};

export default retrieveProducts;
