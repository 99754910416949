import request from "../../request";

const editSurvey = async (survey) => {
  const options = {
    url: `/surveys/${survey?._id}`,
    method: "PUT",
    payload: survey,
  };

  return await request({ ...options });
};

export default editSurvey;
