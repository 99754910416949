import request from "../../request";

const retrieveSurvey = async (id) => {
  const options = {
    url: `/surveys/${id}`,
    method: "GET",
  };

  return await request({ ...options });
};

export default retrieveSurvey;
