import request from '../request';

const askToSync = async (payload) => {
    const options = {
        url: `/results`,
        method: 'POST',
        payload: payload,
        token: null,
        mock: null
    }

    return await request({...options});

};

export default askToSync;
