import React, { createContext, useContext, useEffect, useState } from 'react';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    const savedLanguage = localStorage.getItem('selectedLanguage');
    const [language, setLanguage] = useState(savedLanguage || 'it');

    const changeLanguage = (selectedLanguage) => {
        setLanguage(selectedLanguage);
        localStorage.setItem('selectedLanguage', selectedLanguage);
    };

    const translations = {
        it: {
            common: {
                
                addToCart: "Aggiungi al carrello",
                askToPharmacist: "Parlane col tuo farmacista",
                backToHome: "Torna alla home",
                buy: "Compra",
                confirm: "Conferma",
                congratulations:"Congratulazioni hai terminato il tuo survey!",
                continue: "Prosegui",
                details: "Dettagli",
                enter: "Entra",
                footerHome: 'Proseguendo <b>accetto che Vita Match raccolga dati personali</b> utilizzati per creare suggerimenti personalizzati e per fini statistici.',
                footerSurvey: '<b>Attenzione:</b> Le domande sottoposte e tutte le informazioni contenute nel presente test hanno <b>esclusivamente</b> scopo informativo.',
                forgotPassword: "Password dimenticata?",
                generatedBy: "Generato da",
                greetingSubtitle: "Ecco le nostre raccomandazioni basate sulle tue risposte al questionario. Il nostro obiettivo è quello di aiutarti a stare meglio, per molto tempo!\nGrazie per aver scelto Vita Match!",
                greetingTitle: "Grazie per aver risposto a tutte le domande",
                loadingPage: "Stiamo analizzando le tue risposte",
                login: "Accedi",
                needsQuestion: "Quali sono le tue esigenze?",
                noProductsAvailable: "Siamo spiacenti, al momento non abbiamo individuato prodotti che soddisfino le sue esigenze.",
                noSurvey: "Nessun survey presente",
                previousQuestion: "Domanda precedente",
                productsRecommended: "Prodotti Raccomandati",
                start: "Inizia",
                subtitle: "Scopri il tuo regime di integratori ideale",
                viewProducts: "Prosegui per i prodotti suggeriti",
                warningCredentials:"Inserire delle credenziali valide.",
                warningTooltip: " Le raccomandazioni non costituiscono una prescrizione medica e non sono da intendere come sostituzione di una terapia medica."

            },
            categories: {
                bones: "Ossa",
                defenses: "Difese",
                digestion: "Digestione",
                energy: "Energia",
                hair: "Capelli",
                heart: "Cuore",
                menHealth: "Salute maschile",
                mind: "Mente",
                skin: "Pelle",
                sleep: "Sonno",
                stress: "Stress",
                weight: "Peso",
                womenHealth: "Salute femminile",
            },
        },
        en: {
            common: {
                addToCart: "Add to cart",
                askToPharmacist: "Check with your pharmacist",
                backToHome: "Back to Home",
                buy: "Buy",
                confirm: "Confirm",
                congratulations:"Way to go! You've nailed your survey",
                continue: "Continue",
                details: "Details",
                enter: "Enter",
                footerHome: 'By proceeding, <b>I agree that Vita Match collects my personal informations</b> to provide personalized suggestions and for statistical purposes.',
                footerSurvey: '<b>Attention:</b> The questions and all the information in this test are only for <b>informational purposes</b>.',
                forgotPassword: "Forgot the password?",
                generatedBy: "Generated by",
                greetingSubtitle: "Here are our recommendations based on your questionnaire responses. Our aim is to contribute to your long-lasting well-being!\nThank you for choosing Vita Match!",
                greetingTitle: "Thank you for answering all the questions",
                loadingPage: "We are analyzing your answers",
                login: "Login",
                needsQuestion: "What are your necessities?",
                noProductsAvailable: "We're sorry, at the moment we haven't found any products that meet your needs.",
                noSurvey: "No survey available",
                previousQuestion: "Previous Question",
                productsRecommended: "Products Recommended",
                start: "Start",
                subtitle: "Discover your ideal supplement regimen",
                viewProducts: "View suggested products",
                warningCredentials:"Please enter valid credentials.",
                warningTooltip: "Recommendations are not a medical prescription and should not be considered as a substitute for medical therapy."

            },
            categories: {
                bones: "Bones",
                defenses: "Defenses",
                digestion: "Digestion",
                energy: "Energy",
                hair: "Hair",
                heart: "Heart",
                menHealth: "Men's Health",
                mind: "Mind",
                skin: "Skin",
                sleep: "Sleep",
                stress: "Stress",
                weight: "Weight",
                womenHealth: "Women's Health",
            },
        },
    };


    useEffect(() => {
        // Notifica tutti i componenti che utilizzano il contesto con la nuova lingua
        document.documentElement.lang = language;
    }, [language]);

    return (
        <LanguageContext.Provider value={{ language, changeLanguage, translations }}>
            {children}
        </LanguageContext.Provider>
    );
};

export const useLanguage = () => {
    return useContext(LanguageContext);
};
