import request from "../../request";

const retrieveSurveys = async () => {
  const options = {
    url: `/surveys`,
    method: "GET",
  };

  return await request({ ...options });
};

export default retrieveSurveys;
