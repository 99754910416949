import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Confetti from 'react-confetti';
import { Link, useNavigate } from 'react-router-dom';
import { useLanguage } from '../services/functions/LanguageProvider';
import { Fade } from 'react-awesome-reveal';

const Congratulations = () => {
    const { language, translations } = useLanguage();
    const [confettiActive, setConfettiActive] = useState(true);
    

    return (
        <div className="survey flex-column">
            <Header />
            <div className="container">
                <div className="row">
                    <div className="col-8 text-center question">
                        <Fade direction='down'>
                            <div>
                                <h4>{translations[language]?.common?.congratulations}</h4>
                                <h5>{translations[language]?.common?.viewProducts}</h5>
                                <div className="btn-group">
                                    <Link to="/recommendations">
                                        <button className="main-btn" style={{ border: 'none' }}>{translations[language]?.common?.continue}</button>
                                    </Link>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>

                {/* Aggiungi la prop "active" per gestire l'attivazione dei confetti */}
                {confettiActive && (
                    <Confetti
                        numberOfPieces={800}
                        recycle={false}
                        run={confettiActive}
                        gravity={0.2}
                        tweenDuration={3000}
                        dragfriction={0.1}
                    />
                )}
            </div>
        </div>
    );
};

export default Congratulations;
