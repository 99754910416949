const dateFormater = (data) => {

    if(data){
        const dataParti = data?.split("T")[0].split("-");
        const anno = dataParti[0];
        const mese = dataParti[1];
        const giorno = dataParti[2];
    
        const formatoData = `${giorno}-${mese}-${anno}`;
        return formatoData;
    }else{
        return ''
    }
}

export default dateFormater